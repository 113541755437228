// hooks
import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast from "react-hot-toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
// import { InputText } from "primereact/inputtext";
import { Image } from "primereact/image";

// api related
import Api from "../../api/Api";

// utils
import { useNavigate } from "react-router-dom";
import NewTimeFormatter from "../../utils/NewTimeFormatter";
import csvTemplate from "../../assets/template-import-product.csv";
import PriceFormatter from "../../utils/priceFormatter";
import { InputText } from "primereact/inputtext";
import { useDebounce } from "use-debounce";

const Product = ({ permissions }) => {
  // data limt will efect to page row and pagin
  let data_limit = 10;
  const navigate = useNavigate();
  const uploadFileRef = useRef();
  const uploadFileProductRef = useRef();

  // state
  const [page, setPage] = React.useState(1);
  const [pageInfo, setPageInfo] = useState({});
  const [pageDatTable, setPageDataTable] = useState({ first: 0, rows: 10, page: 1 });
  const [selectItems, setSelectItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [showModalImportError, setShowModalImportError] = useState(false);
  const [importErrorMessages, setImportErrorMessages] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [queryValue] = useDebounce(globalFilter, 1000);

  const [showProductBulksErrorModal, setShowProductBulksErrorModal] = useState(false);
  const [updateProductBulksErrors, setUpdateProductBulksErrors] = useState([]);

  // api calling
  const {
    data: productsData,
    isLoading,
    refetch,
  } = useQuery(
    {
      queryKey: ["product", page, queryValue],
      queryFn: () => getProducts(page),
      keepPreviousData: true,
    },
    { initialData: [] }
  );

  // query
  const { isLoading: uploadCSVLoading, mutate: uploadCSV } = useMutation(async (data) => await Api().post("/product/import", data), {
    onSettled: (response) => {
      if (response.data.status !== 200) {
        toast.error(response.data.message, { duration: 5000 });
        uploadFileProductRef.current.value = null;

        refetch();
        return;
      }

      if (response.data.data?.messages?.length) {
        setImportErrorMessages(response.data.data.messages);
        setShowModalImportError(true);
        refetch();
        return;
      }

      refetch();
      toast.success("Product Imported!", { duration: 4000 });
    },
  });
  const { isLoading: uploadProductCSVLoading, mutate: uploadProductCSV } = useMutation(async (data) => await Api().post("/product/import-update", data), {
    onSettled: async (response) => {
      try {
        if (response.data.status !== 200) {
          setUpdateProductBulksErrors(response?.data?.err || []);
          if (response?.data?.err?.length) {
            setShowProductBulksErrorModal(true);
          }
          throw new Error("An Error Occurred");
        }

        uploadFileProductRef.current.value = null;
        refetch();
        toast.success("Product Updated!", { duration: 4000 });
      } catch (error) {
        console.log(error);

        uploadFileProductRef.current.value = null;
        toast.error("An Error Occurred", { duration: 5000 });
        refetch();
      }
    },
  });
  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(async (data) => await Api().delete("/product", { data }), {
    onSettled: (response) => {
      if (response.data.status === 200) {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.success("Product Deleted!", { duration: 4000 });
      } else {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // functions
  const getProducts = async () => {
    try {
      let query = {
        limit: data_limit,
        page: page,
        sort_at: "DESC",
        sort_by: "created_at",
      };

      if (queryValue) {
        query.query = queryValue;
      }

      const res = await Api().get("/product", { params: query });
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      setPageInfo(res.data.pages);
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const exportProduct = async () => {
    try {
      const response = await Api().get("/product/export?as_string=1&limit=500");

      if (response.data.status !== 200) {
        throw new Error(response.data.message);
      }

      if (!response?.data?.data) {
        throw new Error("Link not found");
      }

      const blobResult = new Blob([response.data.data], {
        type: "text/csv",
      });
      const url = URL.createObjectURL(blobResult);
      const a = document.createElement("a");

      a.href = url;

      a.download = `wardah-all-products`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleUploadCSV = (e) => {
    const file = e.target.files[0];

    if (file.type !== "text/csv") {
      toast.error("File must be CSV");
      return;
    }

    const formData = new FormData();

    formData.append("file", file);
    uploadCSV(formData);
  };
  const handleUploadProductCSV = (e) => {
    const file = e.target.files[0];

    if (file.type !== "text/csv") {
      toast.error("File must be CSV");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    uploadProductCSV(formData);
  };

  const pageHandler = (data) => {
    setPageDataTable(data);
    setPage(data.page + 1);
  };

  const showDeleteItemConfirmation = (data) => {
    setSelectItems([data]);
    setShowDeleteItemModal(true);
  };

  const deleteSelectedItem = () => {
    let payload = {
      ids: [],
    };

    for (let i = 0; i < selectItems.length; i++) {
      payload.ids.push(selectItems[i].id);
    }

    deleteMutate(payload);
  };

  const confirmDeleteItem = () => {
    const data = selectItems[0];

    let payload = {
      ids: [data.id],
    };

    deleteMutate(payload);
  };

  const confirmDeleteSelected = () => {
    setShowDeleteModal(true);
  };

  // child components
  const leftToolbarTemplate = () => {
    return (
      <div className="my-2 flex flex-wrap" style={{ gap: "10px" }}>
        {permissions.create && <Button label="New" icon="pi pi-plus" className="p-button-success " onClick={() => navigate("/dashboard/product/create")} />}
        {permissions.delete && <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectItems.length} />}
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        {permissions.update && <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => navigate(`/dashboard/product/update/${rowData.id}`)} />}
        {permissions.delete && <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => showDeleteItemConfirmation(rowData)} />}
      </div>
    );
  };

  const header = () => {
    return (
      <div className="flex justify-content-between md:align-items-center">
        <div className="">
          <h5 className="m-0">Manage Products</h5>
        </div>

        {permissions.create && (
          <section className="flex flex-column md:flex-row" style={{ gap: "14px" }}>
            <button className="p-button p-component p-button-success ">
              <a href={csvTemplate} style={{ color: "white" }} className="p-button-label p-c" download="Template Import Product">
                Download Template (CSV)
              </a>
            </button>
            <div className="h-fit">
              <Button className="w-full" loading={uploadCSVLoading} label="Export Product (CSV)" onClick={exportProduct} />
            </div>

            <div className="flex align-items-end ">
              <div className="flex flex-column  ">
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search name / barcode / odo code..." />
              </div>
            </div>
          </section>
        )}
      </div>
    );
  };

  const deleteSingleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteItemModal(false)} />
        <Button label="Yes" loading={deleteLoading} icon="pi pi-check" className="p-button-text" onClick={confirmDeleteItem} />
      </>
    );
  };

  const deleteMultipleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteModal(false)} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItem} loading={deleteLoading} />
      </>
    );
  };

  // set page to 1 if gobal filter triggered
  useEffect(() => {
    if (globalFilter.length) {
      setPage(1);
    }
  }, [globalFilter]);

  return (
    <>
      <input onChange={handleUploadCSV} ref={uploadFileRef} style={{ display: "none" }} type="file" />
      <input onChange={handleUploadProductCSV} ref={uploadFileProductRef} style={{ display: "none" }} type="file" />
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card col-12 mx-auto">
            <Toolbar
              className="mb-4"
              left={leftToolbarTemplate}
              right={
                <div className="my-2 flex flex-wrap" style={{ gap: "10px" }}>
                  {permissions.create && (
                    <Button
                      loading={uploadCSVLoading}
                      label="Import Product Create (CSV)"
                      icon="pi pi-upload"
                      className="p-button-secondary "
                      onClick={() => {
                        uploadFileRef.current.click();
                      }}
                    />
                  )}

                  {permissions.update && (
                    <Button
                      loading={uploadProductCSVLoading}
                      label="Import Product Update (CSV)"
                      icon="pi pi-upload"
                      className="p-button-secondary "
                      onClick={() => {
                        uploadFileProductRef.current.click();
                      }}
                    />
                  )}
                </div>
              }
            ></Toolbar>
            <DataTable
              loading={isLoading}
              value={productsData}
              selection={selectItems}
              onSelectionChange={(e) => setSelectItems(e.value)}
              dataKey="id"
              paginator
              rows={data_limit}
              lazy
              onPage={pageHandler}
              first={pageDatTable.first}
              totalRecords={pageInfo.total_data}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              emptyMessage="No Data Found."
              header={header}
              responsiveLayout="scroll"
            >
              <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
              <Column
                header="IMAGE"
                body={(data) => (
                  <div style={{ maxWidth: "80px", maxHeight: "80px", overflow: "hidden" }}>
                    <Image preview imageClassName="object-contain w-full h-full" className="w-full h-full" src={data?.images[0]?.url} alt="image" />
                  </div>
                )}
                headerStyle={{ width: "150px", minWidth: "80px" }}
              ></Column>
              <Column field="sku" header="SKU" headerStyle={{ width: "auto", minWidth: "3rem" }}></Column>
              <Column field="barcode" header="Barcode" headerStyle={{ width: "auto", minWidth: "3rem" }}></Column>
              <Column field="other_code" header="Odo Code" headerStyle={{ width: "auto", minWidth: "3rem" }}></Column>
              <Column field="name" header="NAME" headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
              <Column field="price" header="PRICE" body={(d) => PriceFormatter(+d.price)} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              <Column field="qty" header="QTY" headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              <Column field="created_at" header="CREATED AT" body={(data) => NewTimeFormatter(data.created_at)} headerStyle={{ width: "15%", minWidth: "10rem" }}></Column>
              {permissions.update || permissions.delete ? <Column header="ACTIONS" body={actionBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column> : null}
            </DataTable>
          </div>
        </div>
      </div>

      {/* error import */}
      <Dialog
        visible={showModalImportError}
        className="modal-container"
        header="Import Product Failed"
        modal
        footer={
          <>
            <Button label="Understand" className="p-button-text" onClick={() => setShowModalImportError(false)} />
          </>
        }
        onHide={() => setShowModalImportError(false)}
      >
        {importErrorMessages?.map((message) => (
          <div className="flex align-items-center py-3">
            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
            <div className="">
              <span className="font-bold">Error at row : {message.row}</span>
              <p className="mt-2">{message.message}</p>
            </div>
          </div>
        ))}
      </Dialog>

      {/* delete single */}
      <Dialog visible={showDeleteItemModal} className="modal-container" header="Confirm" modal footer={deleteSingleItemFooter} onHide={() => setShowDeleteItemModal(false)}>
        <div className="flex">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && (
            <span>
              Are you sure you want to delete <b>{selectItems[0]?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      {/* delete multiple */}
      <Dialog visible={showDeleteModal} className="modal-container" header="Confirm" modal footer={deleteMultipleItemFooter} onHide={() => setShowDeleteModal(false)}>
        <div className="flex">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && <span>Are you sure you want to delete the selected Product?</span>}
        </div>
      </Dialog>

      {/* error modal */}
      <Dialog visible={showProductBulksErrorModal} className="modal-container" header="Errors In File" modal onHide={() => setShowProductBulksErrorModal(false)}>
        <DataTable paginator rows={10} value={updateProductBulksErrors}>
          <Column field="row" header="Row"></Column>
          <Column field="message" header="Message"></Column>
        </DataTable>
      </Dialog>

      {/* <DevTool control={updateForm.control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(Product, comparisonFn);
