import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useQuery } from "react-query";
import toast from "react-hot-toast";
import Api from "../api/Api";
import { Calendar } from "primereact/calendar";
import TransactionStatus from "../assets/data/product_shipping_status.json";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import moment from "moment";
import { Button } from "primereact/button";
import TimeFormatter from "../utils/TimeFormatter";
import PriceFormatter from "../utils/priceFormatter";
import { InputText } from "primereact/inputtext";
import { useDebounce } from "use-debounce";
import { TabView, TabPanel } from "primereact/tabview";
import NewTimeFormatter from "../utils/NewTimeFormatter";

const currentDate = new Date();
let year = moment(currentDate).format("YYYY");
let month = moment(currentDate).format("MM");
let day = moment(currentDate).format("DD");

let fromDate = `${year}-${month}-${String(+day - +day + 1).padStart(2, "0")}`;
let toDate = `${year}-${month}-${day}`;

function areDatesAtLeastThreeMonthsApart(date1, date2) {
  // Convert strings to Date objects if needed
  date1 = new Date(date1);
  date2 = new Date(date2);

  // Swap dates if date1 is later than date2
  if (date1 > date2) {
    let temp = date1;
    date1 = date2;
    date2 = temp;
  }

  // Calculate the difference in months between date1 and date2
  let diffInMonths = (date2.getFullYear() - date1.getFullYear()) * 12 + (date2.getMonth() - date1.getMonth());

  // Check if the difference is 3 or more months
  return diffInMonths >= 3;
}

const ExportSection = () => {
  const [exportDates, setExportDates] = useState(null);

  const handleExport = async () => {
    try {
      if (!exportDates || !exportDates.length) {
        throw new Error("Please select date before export");
      }

      let startDate = moment(exportDates[0]).format("YYYY-MM-DD");
      let endDate = exportDates[1] ? moment(exportDates[1]).format("YYYY-MM-DD") : startDate;

      const response = await Api().get("/transaction/export-dashboard", {
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      });

      if (response.data.status !== 200) {
        throw new Error(response.data.message);
      }

      var a = document.createElement("a");
      a.href = response.data.data;
      a.target = "_blank";
      a.click();
      setExportDates(null);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="col-12 card flex ">
      <div className="flex w-full flex-column md:flex-row justify-content-between align-items-center">
        <h4 className="uppercase font-bold">Dashboard Overview</h4>
      </div>

      <div className="flex align-items-end">
        <div className="flex flex-column">
          <label htmlFor="">Export Sales Data</label>
          <Calendar className="mt-1" style={{ minWidth: "200px" }} showButtonBar value={exportDates} onChange={(e) => setExportDates(e.value)} selectionMode="range" placeholder="Select date range" />
        </div>
        <div className="ml-2">
          <Button disabled={!exportDates?.length} onClick={handleExport}>
            Export
          </Button>
        </div>
      </div>
    </div>
  );
};

const SalesProduct = ({ countryOptions = [] }) => {
  const [selectedCountry, setSelectedCountry] = useState();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [sort, setSort] = useState(-1);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["sales-products", selectedCountry, sort],
    queryFn: async () => await getData(),
  });

  const getData = async () => {
    try {
      let query = {};

      if (selectedCountry !== undefined) {
        query.country_id = selectedCountry;
      }

      query.sort_type = sort;

      if (forceRefresh) {
        query.force_refresh = 1;
      } else {
        setForceRefresh(false);
        query.force_refresh = 0;
      }

      const res = await Api().get(`/sales-product`, { params: query });
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  return (
    <React.Fragment>
      <div className="">
        {/* filter */}
        <div className="card flex  flex-column  lg:flex-row justify-content-between  mb-0">
          <h4 className="font-semibold">Product Sales</h4>
          <div className="flex flex-column md:flex-row align-items-end" style={{ gap: "14px" }}>
            <div className="flex flex-column w-full">
              <label htmlFor="">Sort By Sold</label>
              <Dropdown
                optionLabel="label"
                optionValue="value"
                value={sort}
                showButtonBar
                filter
                style={{ minWidth: "180px" }}
                showClear
                options={[
                  {
                    label: "Most Sold",
                    value: -1,
                  },
                  {
                    label: "Least Sold",
                    value: 1,
                  },
                ]}
                className="w-full  mt-1"
                onChange={(e) => {
                  setTimeout(() => {
                    setSort(e.target.value);
                  }, 100);
                  setForceRefresh(true);
                }}
                placeholder="Choose option"
              />
            </div>
            <div className="flex flex-column w-full">
              <label htmlFor="">Country Option</label>
              <Dropdown
                optionLabel="label"
                optionValue="value"
                value={selectedCountry}
                showButtonBar
                filter
                style={{ minWidth: "200px" }}
                showClear
                options={countryOptions?.map((d) => ({ label: d.name, value: d.id }))}
                className="w-full  mt-1"
                onChange={(e) => {
                  setSelectedCountry(e.target.value);
                }}
                placeholder="Choose option"
              />
            </div>
            <div className="flex flex-column w-full">
              <label htmlFor=""></label>
              <Button
                loading={isLoading}
                onClick={() => {
                  setForceRefresh(true);
                  setTimeout(() => {
                    refetch();
                  }, 200);
                }}
                label="Refresh"
                icon="pi pi-sync"
              />
            </div>
          </div>
        </div>
        <div className="grid mt-0">
          <div className="col-12">
            <div className="card h-full">
              <section className="overflow-x-auto">
                <DataTable loading={isLoading} className="w-full" value={data || []}>
                  <Column field="barcode" header="Barcode"></Column>
                  <Column field="product_name" style={{ minWidth: "180px" }} header="Product Name"></Column>
                  <Column field="odoo_code" style={{ minWidth: "120px" }} header="Oodo Code"></Column>
                  <Column field="category" style={{ minWidth: "120px" }} header="Category"></Column>
                  <Column field="sub_category" style={{ minWidth: "120px" }} header="Sub Category"></Column>
                  <Column field="qty" style={{ minWidth: "120px" }} header="Quantity"></Column>
                  <Column field="price" style={{ minWidth: "120px" }} header="Price" body={(data) => PriceFormatter(data.price)}></Column>
                </DataTable>
              </section>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const VoucherSummary = ({ countryOptions = [] }) => {
  const [filterDatesTemp, setFilterDatesTemp] = useState([new Date(fromDate), new Date(toDate)]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [forceRefresh, setForceRefresh] = useState(false);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["voucher-summary", selectedCountry, filterDatesTemp],
    queryFn: async () => await getData(),
  });

  const getData = async () => {
    try {
      let query = {};

      if (selectedCountry !== undefined) {
        query.country_id = selectedCountry;
      }

      if (forceRefresh) {
        query.force_refresh = 1;
      } else {
        setForceRefresh(false);
        query.force_refresh = 0;
      }

      if (filterDatesTemp) {
        let startDate = moment(filterDatesTemp[0]).format("YYYY-MM-DD");
        let endDate = filterDatesTemp[1] ? moment(filterDatesTemp[1]).format("YYYY-MM-DD") : startDate;

        query.start_date = startDate;
        query.end_date = endDate;
      }

      const res = await Api().get(`/voucher-summary`, { params: query });
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  return (
    <React.Fragment>
      <div className="">
        {/* filter */}
        <div className="card flex  flex-column  lg:flex-row justify-content-between  mb-0">
          <h4 className="font-semibold uppercase">Voucher Detail</h4>
          <div className="flex flex-column md:flex-row align-items-end" style={{ gap: "14px" }}>
            <div className="flex flex-column w-full">
              <label htmlFor="">Country Option</label>
              <Dropdown
                optionLabel="label"
                optionValue="value"
                value={selectedCountry}
                showButtonBar
                filter
                style={{ minWidth: "280px" }}
                showClear
                options={countryOptions?.map((d) => ({ label: d.name, value: d.id }))}
                className="w-full  mt-1"
                onChange={(e) => {
                  setSelectedCountry(e.target.value);
                }}
                placeholder="Choose option"
              />
            </div>
            <div className="flex align-items-end  w-full">
              <div className="flex flex-column  w-full">
                <label htmlFor="">Date</label>
                <Calendar
                  showButtonBar
                  className="mt-1"
                  value={filterDatesTemp}
                  onChange={(e) => {
                    let fromDate = e.target?.value[0];
                    let toDate = e.target?.value[1];
                    if (fromDate && toDate && areDatesAtLeastThreeMonthsApart(fromDate, toDate)) {
                      toast.error("Max is 3 months");
                      setFilterDatesTemp([fromDate]);
                      return;
                    } else {
                      setFilterDatesTemp(e.value);
                    }
                  }}
                  selectionMode="range"
                  placeholder="Select date range"
                />
              </div>
            </div>
            <div className="flex flex-column w-full">
              <label htmlFor=""></label>
              <Button
                loading={isLoading}
                onClick={() => {
                  setForceRefresh(true);
                  setTimeout(() => {
                    refetch();
                  }, 200);
                }}
                label="Refresh"
                icon="pi pi-sync"
              />
            </div>
          </div>
        </div>
        <div className="grid mt-0">
          <div className="col-12">
            <div className="card h-full">
              <section className="overflow-x-auto">
                <DataTable loading={isLoading} className="w-full" value={data || []}>
                  <Column field="title" style={{ minWidth: "10rem" }} header="Voucher Name"></Column>
                  {/* <Column field="usage_in" header="Usage In"></Column> */}
                  <Column field="count" header="Voucher usage"></Column>
                </DataTable>
              </section>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const UserSummary = () => {
  const [activeStatus, setActiveStatus] = useState(1);
  const [userName, setUserName] = useState("");
  const [userNameValue] = useDebounce(userName, 1000);
  const [forceRefresh, setForceRefresh] = useState(false);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["user-summary", activeStatus, userNameValue],
    queryFn: async () => await getData(),
  });

  const getData = async () => {
    try {
      let query = {};

      if (userName.length) {
        query.name = userName;
      }
      if (activeStatus !== undefined) {
        query.active_status = activeStatus;
      }
      if (forceRefresh) {
        query.force_refresh = 1;
      } else {
        setForceRefresh(false);
        query.force_refresh = 0;
      }

      const res = await Api().get(`/user-summary?limit=10`, { params: query });
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  return (
    <React.Fragment>
      <div className="">
        {/* filter */}
        <div className="card flex  flex-column  lg:flex-row justify-content-between  mb-0">
          <h4 className="font-semibold">USER SUMMARY</h4>
          <div className="flex flex-column md:flex-row align-items-end" style={{ gap: "14px" }}>
            <div className="flex align-items-end  w-full">
              <div className="flex flex-column  w-full">
                <label htmlFor="">Search By Username</label>
                <InputText className="mt-1  w-full" style={{ minWidth: "280px" }} value={userName} onChange={(e) => setUserName(e.target.value)} placeholder="Input user name" />
              </div>
            </div>
            <div className="flex flex-column w-full">
              <label htmlFor="">User Status</label>
              <Dropdown
                optionLabel="label"
                optionValue="value"
                value={activeStatus}
                showButtonBar
                filter
                style={{ minWidth: "180px" }}
                showClear
                options={[
                  {
                    label: "Active",
                    value: 1,
                  },
                  {
                    label: "Inactive",
                    value: 0,
                  },
                ]}
                className="w-full  mt-1"
                onChange={(e) => {
                  setActiveStatus(e.target.value);
                }}
                placeholder="Choose option"
              />
            </div>
            <div className="flex flex-column w-full">
              <label htmlFor=""></label>
              <Button
                loading={isLoading}
                onClick={() => {
                  setForceRefresh(true);
                  setTimeout(() => {
                    refetch();
                  }, 200);
                }}
                label="Refresh"
                icon="pi pi-sync"
              />
            </div>
          </div>
        </div>
        <div className="grid mt-0">
          <div className="col-12">
            <div className="card h-full">
              <section className="overflow-x-auto">
                <DataTable loading={isLoading} className="w-full" value={data || []}>
                  <Column field="id" header="id"></Column>
                  <Column field="email" header="E-Mail"></Column>
                  <Column field="name" style={{ minWidth: "120px" }} header="Full Name"></Column>
                  <Column field="location" style={{ minWidth: "120px" }} header="Location"></Column>
                  <Column field="phone_number" style={{ minWidth: "180px" }} header="Phone Number"></Column>
                  <Column field="created_at" header="Date Registered" style={{ minWidth: "180px" }} body={(d) => TimeFormatter(d.created_at)}></Column>
                  <Column field="last_open_at" style={{ minWidth: "180px" }} header="Last Time Opened Website" body={(data) => (data.last_open_at ? TimeFormatter(data.last_open_at) : "-")}></Column>
                  <Column field="dob" header="Birth Date" style={{ minWidth: "10rem" }} body={(data) => (data.dob ? NewTimeFormatter(data.dob) : "-")}></Column>
                  <Column field="country" style={{ minWidth: "120px" }} header="Country"></Column>
                  <Column field="address" style={{ minWidth: "180px" }} header="Address"></Column>
                  <Column field="postal_code" style={{ minWidth: "120px" }} header="Postcode"></Column>
                  {/* <Column field="province" style={{ minWidth: "120px" }} header="Province"></Column> */}
                  <Column field="city" style={{ minWidth: "120px" }} header="City"></Column>
                  <Column field="active_status" header="Status" body={(d) => (d.active_status ? "Active" : "Inactive")}></Column>

                  <Column field="count_transaction" style={{ minWidth: "120px" }} header="Total Order"></Column>
                  {/* <Column field="count_discount" style={{ minWidth: "120px" }} header="Total Usage Voucher"></Column> */}
                  <Column field="total_transaction" style={{ minWidth: "120px" }} header="Total Purchase" body={(d) => PriceFormatter(d.total_transaction)}></Column>
                </DataTable>
              </section>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const ProductCountry = ({ countryOptions = [] }) => {
  const [selectedCountry, setSelectedCountry] = useState();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [filterDatesTemp, setFilterDatesTemp] = useState([new Date(fromDate), new Date(toDate)]);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["product-country", selectedCountry, filterDatesTemp],
    queryFn: async () => await getData(),
  });

  const getData = async () => {
    try {
      let query = {};

      if (forceRefresh) {
        query.force_refresh = 1;
      } else {
        setForceRefresh(false);
        query.force_refresh = 0;
      }

      if (selectedCountry !== undefined) {
        query.country_id = selectedCountry;
      }
      if (filterDatesTemp) {
        let startDate = moment(filterDatesTemp[0]).format("YYYY-MM-DD");
        let endDate = filterDatesTemp[1] ? moment(filterDatesTemp[1]).format("YYYY-MM-DD") : startDate;

        query.start_date = startDate;
        query.end_date = endDate;
      }

      const res = await Api().get(`/product-country`, { params: query });
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      setForceRefresh(false);
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  return (
    <React.Fragment>
      <div className="">
        {/* filter */}
        <div className="card flex  flex-column  lg:flex-row justify-content-between  mb-0">
          <h4 className="font-semibold">PRODUCT COUNTRIES</h4>
          <div className="flex flex-column md:flex-row align-items-end" style={{ gap: "14px" }}>
            <div className="flex flex-column w-full">
              <label htmlFor="">Country Option</label>
              <Dropdown
                optionLabel="label"
                optionValue="value"
                value={selectedCountry}
                showButtonBar
                filter
                style={{ minWidth: "200px" }}
                showClear
                options={countryOptions?.map((d) => ({ label: d.name, value: d.id }))}
                className="w-full  mt-1"
                onChange={(e) => {
                  setSelectedCountry(e.target.value);
                }}
                placeholder="Choose option"
              />
            </div>
            <div className="flex align-items-end  w-full">
              <div className="flex flex-column  w-full">
                <label htmlFor="">Date</label>
                <Calendar
                  showButtonBar
                  className="mt-1"
                  value={filterDatesTemp}
                  onChange={(e) => {
                    let fromDate = e.target?.value[0];
                    let toDate = e.target?.value[1];
                    if (fromDate && toDate && areDatesAtLeastThreeMonthsApart(fromDate, toDate)) {
                      toast.error("Max is 3 months");
                      setFilterDatesTemp([fromDate]);
                      return;
                    } else {
                      setFilterDatesTemp(e.value);
                    }
                  }}
                  selectionMode="range"
                  placeholder="Select date range"
                />
              </div>
            </div>
            <div className="flex flex-column w-full">
              <label htmlFor=""></label>
              <Button
                loading={isLoading}
                onClick={() => {
                  setForceRefresh(true);
                  setTimeout(() => {
                    refetch();
                  }, 200);
                }}
                label="Refresh"
                icon="pi pi-sync"
              />
            </div>
          </div>
        </div>
        <div className="grid mt-0">
          <div className="col-12 md:col-6">
            <div className="card h-full">
              <h5 className="uppercase font-medium underline" style={{ fontSize: "14px" }}>
                SKU Trend
              </h5>
              <section className="overflow-x-auto">
                <DataTable loading={isLoading} value={data?.product_trend || []}>
                  <Column field="name" header="Product Name"></Column>
                  <Column field="count" header="Count (per month)"></Column>
                  <Column field="price" header="Value (price)" body={(d) => PriceFormatter(d.price)}></Column>
                  <Column field="country" header="Country"></Column>
                </DataTable>
              </section>
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className="card h-full">
              <h5 className="uppercase font-medium underline" style={{ fontSize: "14px" }}>
                List Destination
              </h5>
              <section className="overflow-x-auto">
                <DataTable loading={isLoading} value={data?.countries || []}>
                  <Column field="name" header="Destination"></Column>
                  <Column field="count" header="Total Order Of The Month"></Column>
                </DataTable>
              </section>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const SalesSumary = ({ categories = [], countryOptions = [] }) => {
  const [statusFilter, setStatusFilter] = useState();
  const [filterDatesTemp, setFilterDatesTemp] = useState([new Date(fromDate), new Date(toDate)]);
  const [selectCategory, setSelectCategory] = useState();
  const [userName, setUserName] = useState("");
  const [selectedCountry, setSelectedCountry] = useState();
  const [userNameValue] = useDebounce(userName, 1000);
  const [forceRefresh, setForceRefresh] = useState(false);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["sales-summary", filterDatesTemp, statusFilter, selectCategory, userNameValue, selectedCountry],
    queryFn: async () => await getSales(),
  });

  const getSales = async () => {
    try {
      let query = {};

      if (selectCategory?.length) {
        query.category_ids = selectCategory.join(",");
      }

      if (statusFilter !== undefined) {
        query.status = statusFilter;
      }

      if (forceRefresh) {
        query.force_refresh = 1;
      } else {
        setForceRefresh(false);
        query.force_refresh = 0;
      }

      if (selectedCountry !== undefined) {
        query.country_name = selectedCountry;
      }

      if (filterDatesTemp) {
        let startDate = moment(filterDatesTemp[0]).format("YYYY-MM-DD");
        let endDate = filterDatesTemp[1] ? moment(filterDatesTemp[1]).format("YYYY-MM-DD") : startDate;

        query.start_date = startDate;
        query.end_date = endDate;
      }

      if (userNameValue && userNameValue.length > 0) {
        query.user_name = userNameValue;
      }

      const res = await Api().get(`/sales-summary`, { params: query });
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  return (
    <React.Fragment>
      <div className="">
        {/* filter */}
        <div className="card flex  flex-column  lg:flex-row justify-content-between  mb-0">
          <h4 className="font-semibold">SALES SUMMARY</h4>
          <div className="flex flex-column md:flex-row align-items-end" style={{ gap: "14px" }}>
            <div className="flex flex-column w-full">
              <label htmlFor="">Transaction Status</label>
              <Dropdown
                optionLabel="label"
                optionValue="value"
                value={statusFilter}
                options={TransactionStatus.map((d, index) => ({ label: d, value: index }))}
                className="w-full  mt-1"
                onChange={(e) => {
                  setStatusFilter(e.target.value);
                }}
                placeholder="Choose option"
              />
            </div>
            <div className="flex align-items-end  w-full">
              <div className="flex flex-column  w-full">
                <label htmlFor="">Category & Sub Category</label>
                <MultiSelect showButtonBar className="mt-1" value={selectCategory} onChange={(e) => setSelectCategory(e.value)} options={categories.map((cat) => ({ label: cat.name, value: cat.id }))} showClear filter filterBy="label" placeholder="Select option" />
              </div>
            </div>
            {/* <div className="flex align-items-end  w-full">
              <div className="flex flex-column  w-full">
                <label htmlFor="">Search By Username</label>
                <InputText className="mt-1  w-full" value={userName} onChange={(e) => setUserName(e.target.value)} placeholder="Input user name" />
              </div>
            </div> */}
            <div className="flex flex-column w-full">
              <label htmlFor="">Country Option</label>
              <Dropdown
                optionLabel="label"
                optionValue="value"
                value={selectedCountry}
                showButtonBar
                filter
                style={{ minWidth: "200px" }}
                showClear
                options={countryOptions?.map((d) => ({ label: d.name, value: d.name }))}
                className="w-full  mt-1"
                onChange={(e) => {
                  setSelectedCountry(e.target.value);
                }}
                placeholder="Choose option"
              />
            </div>
            <div className="flex align-items-end  w-full">
              <div className="flex flex-column  w-full">
                <label htmlFor="">Date</label>
                <Calendar
                  showButtonBar
                  className="mt-1"
                  value={filterDatesTemp}
                  onChange={(e) => {
                    let fromDate = e.target?.value[0];
                    let toDate = e.target?.value[1];
                    if (fromDate && toDate && areDatesAtLeastThreeMonthsApart(fromDate, toDate)) {
                      toast.error("Max is 3 months");
                      setFilterDatesTemp([fromDate]);
                      return;
                    } else {
                      setFilterDatesTemp(e.value);
                    }
                  }}
                  selectionMode="range"
                  placeholder="Select date range"
                />
              </div>
            </div>
            <div className="flex flex-column w-full">
              <label htmlFor=""></label>
              <Button
                loading={isLoading}
                onClick={() => {
                  setForceRefresh(true);
                  setTimeout(() => {
                    refetch();
                  }, 200);
                }}
                label="Refresh"
                icon="pi pi-sync"
              />
            </div>
          </div>
        </div>
        <div className="grid mt-0">
          <div className="col-12">
            <div className="card h-full">
              <h5 className="uppercase font-medium underline" style={{ fontSize: "14px" }}>
                SALES BY COUNTRIES
              </h5>
              <section className="overflow-x-auto">
                {/* <DataTable loading={isLoading} value={data?.summaries || []}>
                  <Column field="country" header="Country"></Column>
                  <Column style={{ minWidth: "120px" }} field="ytd" header="HET Sales"></Column>
                  <Column field="aov" header="AOV" body={(data) => PriceFormatter(data.aov)}></Column>
                  <Column style={{ minWidth: "120px" }} field="shipping_price" body={(data) => PriceFormatter(data.shipping_price)} header="Shipping FEE"></Column>
                  <Column style={{ minWidth: "180px" }} field="discount_shipping_price" body={(data) => PriceFormatter(data.discount_shipping_price)} header="Shipping Discount"></Column>
                  <Column style={{ minWidth: "120px" }} field="subtotal" body={(data) => PriceFormatter(data.subtotal)} header="NET SALE"></Column>
                  <Column style={{ minWidth: "120px" }} field="user_count" header="User"></Column>
                  <Column style={{ minWidth: "120px" }} field="user_repeat" header="User Repeat"></Column>
                  <Column style={{ minWidth: "120px" }} field="order_count" header="Order"></Column>
                  <Column style={{ minWidth: "120px" }} field="basket_size" header="Basket Size" body={(data) => Number.parseFloat(data.basket_size).toFixed(2)}></Column>
                  <Column style={{ minWidth: "120px" }} field="l3m" header="AVG L3M"></Column>
                  <Column style={{ minWidth: "180px" }} field="total_amount" body={(data) => PriceFormatter(data.total_amount)} header="CURRENT YEAR"></Column>

                  <Column style={{ minWidth: "120px" }} field="growth_lm" header="VS L3M(%)"></Column>
                  <Column style={{ minWidth: "120px" }} field="growth_l3m" header="VS L3M(%)"></Column>
                  <Column style={{ minWidth: "120px" }} field="growth_ly" header="VS LY(%)"></Column>
                </DataTable> */}
                <DataTable loading={isLoading} value={data?.summaries || []}>
                  <Column field="country" header="Country"></Column>
                  {/* <Column style={{ minWidth: "120px" }} field="total_amount" body={(data) => PriceFormatter(data.total_amount)} header="Price"></Column> */}
                  <Column style={{ minWidth: "120px" }} field="shipping_price" body={(data) => PriceFormatter(data.shipping_price)} header="Shipping FEE"></Column>
                  <Column style={{ minWidth: "180px" }} field="discount_shipping_price" body={(data) => PriceFormatter(data.discount_shipping_price)} header="Shipping Discount"></Column>
                  <Column style={{ minWidth: "120px" }} field="subtotal" body={(data) => PriceFormatter(data.subtotal)} header="Net Sale"></Column>
                  <Column style={{ minWidth: "120px" }} field="user_count" header="User Count"></Column>
                  <Column style={{ minWidth: "120px" }} field="user_repeat" header="User Repeat"></Column>
                  <Column style={{ minWidth: "120px" }} field="order_count" header="Order"></Column>
                  {/* <Column style={{ minWidth: "120px" }} field="basket_size" header="Basket Size" body={(data) => Number.parseFloat(data.basket_size).toFixed(2)}></Column> */}
                  <Column field="aov" header="AOV" body={(data) => PriceFormatter(data.aov)}></Column>
                  <Column style={{ minWidth: "180px" }} field="total_amount" body={(data) => PriceFormatter(data.total_amount)} header="purchase order"></Column>
                  <Column style={{ minWidth: "120px" }} field="l3m" header="AVG L3M"></Column>
                  <Column style={{ minWidth: "120px" }} field="ytd" header="YTD"></Column>
                  <Column style={{ minWidth: "120px" }} field="growth_lm" header="VS LM(%)"></Column>
                  <Column style={{ minWidth: "120px" }} field="growth_l3m" header="VS L3M(%)"></Column>
                  <Column style={{ minWidth: "120px" }} field="growth_ly" header="VS LY(%)"></Column>
                </DataTable>
              </section>
            </div>
          </div>
          <div className="col-12">
            <div className="card h-full">
              <h5 className="uppercase font-medium underline" style={{ fontSize: "14px" }}>
                Order Summary
              </h5>
              <section className="overflow-x-auto">
                <DataTable paginator rows={10} rowsPerPageOptions={[5, 10, 25]} loading={isLoading} className="w-full" value={data?.detail_summaries || []}>
                  <Column field="country" header="Country" style={{ minWidth: "140px" }}></Column>
                  <Column field="created_at" header="Date Order" style={{ minWidth: "140px" }} body={(data) => TimeFormatter(data.created_at)}></Column>
                  <Column field="payment_date" header="Date Paid" style={{ minWidth: "140px" }} body={(data) => TimeFormatter(data.payment_date)}></Column>
                  <Column field="dc" header="DC" style={{ minWidth: "140px" }}></Column>
                  <Column field="user_id" header="User ID" style={{ minWidth: "140px" }}></Column>
                  <Column field="user_email" header="E-Mail"></Column>
                  <Column field="order_id" header="Order ID"></Column>
                  <Column field="status" header="Status"></Column>
                  <Column field="payment_method" style={{ minWidth: "160px" }} header="Payment Method"></Column>
                  <Column field="awb" header="AWB"></Column>
                  {/* <Column field="logistic_invoice" header="Logistic Invoice"></Column> */}
                  <Column field="price" header="Price" body={(data) => PriceFormatter(data.price)}></Column>
                  <Column field="discount_voucher" style={{ minWidth: "160px" }} header="Discount Voucher"></Column>
                  <Column field="shipping_discount" style={{ minWidth: "160px" }} body={(data) => (data.shipping_discount ? PriceFormatter(data.shipping_discount) : "-")} header="Shipping Discount"></Column>
                  <Column field="net_sales" style={{ minWidth: "140px" }} body={(data) => PriceFormatter(data.net_sales)} header="Net Sales"></Column>
                  <Column field="shipping_fee" style={{ minWidth: "140px" }} body={(data) => PriceFormatter(data.shipping_fee)} header="Shipping Fee"></Column>
                  <Column field="total_payment" style={{ minWidth: "140px" }} body={(data) => PriceFormatter(data.total_payment)} header="Total Payment"></Column>
                </DataTable>
              </section>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const Dashboard = () => {
  const { data: catData } = useQuery("product-category", () => getProductCategory(), { initialData: [] });

  const { data: countryOptions } = useQuery({
    queryKey: ["countries"],
    queryFn: async () => await getCounty(),
  });

  const getProductCategory = async () => {
    try {
      const res = await Api().get("/category?page=1&limit=999999");
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      const results = res.data.data.filter((cat) => cat.tag === 0 && !cat.parent_id);
      let temp_result = [];

      results.forEach((parent) => {
        temp_result.push({ id: parent.id, name: parent.name });
        for (let i = 0; i < parent.child_categories.length; i++) {
          if (parent.child_categories[i].tag === 0) {
            temp_result.push({ id: parent.child_categories[i].id, name: parent.child_categories[i].full_name });
          }
        }
      });

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getCounty = async () => {
    try {
      const res = await Api().get(`/country?page=1&limit=50&sort_by=name&sort_type=asc&active_status=1`);
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  return (
    <div className="">
      <ExportSection />

      <div className="card">
        <TabView>
          <TabPanel header="Sales Summary">
            <SalesSumary categories={catData} countryOptions={countryOptions} />
          </TabPanel>
          <TabPanel header="Product Sales">
            <SalesProduct countryOptions={countryOptions} />
          </TabPanel>
          <TabPanel header="Voucher Detail">
            <VoucherSummary countryOptions={countryOptions} />
          </TabPanel>
          <TabPanel header="User Summary">
            <UserSummary />
          </TabPanel>
          <TabPanel header="Product Countries">
            <ProductCountry countryOptions={countryOptions} />
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(Dashboard, comparisonFn);
