// hooks
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast from "react-hot-toast";
import { InputText } from "primereact/inputtext";

// api related

// utils

import Api from "../api/Api";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";

const ProductVariant = ({ permissions }) => {
  // state
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectItems, setSelectItems] = useState([]);
  const [products, setProducts] = useState([]);
  const [productsChanges, setProductChanges] = useState([]);

  // api calling
  const { isLoading, refetch } = useQuery(["product", "bulk"], async () => await getData());
  const { isLoading: updateLoading, mutate } = useMutation(async (data) => await Api().post("/product/bulk-stock", data), {
    onSettled: async (response) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response.data.message);
        }

        refetch();
        setProductChanges([]);
        toast.success("Qty Updated", { duration: 4000 });
      } catch (error) {
        toast.error(error?.message || "Failed while update qty");
      }
    },
  });

  // functions
  const getData = async () => {
    try {
      const response = await Api().get("/product/stock");

      if (response.data.status !== 200) {
        throw new Error(response.data.message);
      }

      let result = [...response.data.data];

      setProducts(result);
      return response.data.data;
    } catch (error) {
      toast.error("failed to get product variants");
    }
  };

  const onRowEditComplete = (row, originalQty, qty) => {
    let _products = [...products];
    let rowIndex = _products.findIndex((p) => p.name === row.name);
    _products[rowIndex].qty = qty;

    let _product_changes = [...productsChanges];
    let product_changes_index = _product_changes.findIndex((pc) => pc.name === row.name);

    if (product_changes_index > -1) {
      _product_changes[product_changes_index].qty = qty;
    } else {
      _product_changes.push({ ..._products[rowIndex], original_qty: originalQty });
    }

    setProductChanges(_product_changes);
    setProducts(_products);
  };

  const handleUpdate = () => {
    console.log(productsChanges);
    mutate({ products: productsChanges });
  };

  // child components
  const qtyEditor = (row) => {
    return <InputNumber showButtons min={0} value={row.qty} onValueChange={(e) => onRowEditComplete(row, row.qty, e.target.value)} />;
  };

  const header = () => {
    return (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <h5 className="m-0">Manage Product Total Stock</h5>
        <div className="flex" style={{ gap: "14px" }}>
          <div className="block w-auto mt-2 md:mt-0 p-input-icon-left">
            <i className="pi pi-search" />
            <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
          </div>
          <div className="">
            <Button label="Save Changes" disabled={!productsChanges.length} loading={updateLoading} onClick={handleUpdate} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <DataTable
              loading={isLoading}
              value={products}
              selection={selectItems}
              onSelectionChange={(e) => setSelectItems(e.value)}
              globalFilterFields={["name", "barcode", "other_code"]}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="No data found."
              header={header}
              responsiveLayout="scroll"
            >
              <Column field="name" header="Product Name" sortable headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
              <Column field="barcode" header="Barcode" body={(d) => (d.barcode ? d.barcode : "-")} sortable headerStyle={{ width: "auto", minWidth: "5rem" }}></Column>
              <Column field="Odo Code" header="other_code" body={(d) => (d.other_code ? d.other_code : "-")} sortable headerStyle={{ width: "auto", minWidth: "5rem" }}></Column>
              <Column field="qty" header="Available Stock" sortable body={qtyEditor} style={{ width: "20%" }}></Column>
              <Column
                field="_"
                header=""
                body={(body) => {
                  const currentData = productsChanges.find((p) => p.name === body.name);
                  if (currentData && currentData.qty !== currentData.original_qty) return <Tag severity="warning" value="Quantity Change"></Tag>;
                  return null;
                }}
                style={{ width: "20%" }}
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(ProductVariant, comparisonFn);
