// hooks
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// conponents
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { confirmPopup, ConfirmPopup } from "primereact/confirmpopup";
import { InputTextarea } from "primereact/inputtextarea";
import { TabPanel, TabView } from "primereact/tabview";

// utils
import classNames from "classnames";

// api related
import Api from "../../api/Api";
import { InputNumber } from "primereact/inputnumber";
import TransactionStatus from "../../assets/data/product_shipping_status.json";
import { Dropdown } from "primereact/dropdown";
import NewTimeFormatter from "../../utils/NewTimeFormatter";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Image } from "primereact/image";
import priceFormatter from "../../utils/priceFormatter";
import { Timeline } from "primereact/timeline";
import TimeFormatter from "../../utils/TimeFormatter";
import discountType from "../../assets/data/discount_type.json";

const TransactionListUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  // hooks
  const { control, handleSubmit, reset } = useForm();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [trackHistory, setTrackHistory] = useState([]);

  // query
  const { data: transactionData, refetch } = useQuery(["transaction", id], () => getTransactionDetails());
  const { isLoading: updateLoading, mutate: updateMutate } = useMutation(async (data) => await Api().post("/transaction/edit", data), {
    onSettled: async (response) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response?.data?.message || response?.data?.err);
        }
        navigate("/dashboard/list-of-order");
        toast.success("Transaction updated!");
      } catch (error) {
        toast.error(error.message || "something went wrong");
      }
    },
  });

  // functions
  const getTransactionDetails = async () => {
    try {
      if (id === "null") {
        throw new Error("Transaction id not found.");
      }

      const res = await Api().get("/transaction/detail/" + id);

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }
      let result = res.data.data;

      if (result?.resi) {
        const history = await getTrackHistory(result.resi);
        if (history[0]?.track_history.length) {
          setTrackHistory(history[0]?.track_history);
        }
      }

      reset(result);

      return result;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const getTrackHistory = async (awb) => {
    try {
      const res = await Api().get("/courier/resi-status?resi=" + awb);

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }
      let result = res.data.data;
      return result;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const onSubmit = (data) => {
    updateMutate({
      status: data.status,
      transaction_id: data._id,
    });
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Update Order
          </h4>
        </div>
      </React.Fragment>
    );
  };

  const RequestRefund = () => {
    const { isLoading, mutate } = useMutation(async (data) => await Api().post("/transaction/refund", data), {
      onSettled: async (response) => {
        try {
          if (response.data.status !== 200) {
            throw new Error(response?.data?.message || response?.data?.err);
          }

          refetch();
          setCurrentTabIndex(0);
          toast.success("Transaction status changed!");
        } catch (error) {
          toast.error(error.message || "something went wrong");
        }
      },
    });

    const confirmRequest = (event) => {
      confirmPopup({
        target: event.currentTarget,
        message: "Are you sure you want to proceed?",
        icon: "pi pi-exclamation-triangle",
        defaultFocus: "accept",
        accept: () => {
          mutate({
            transaction_id: transactionData.id,
            status: 11,
            return_stock_product: 0, //0 -> no | 1 -> yes
          });
        },
      });
    };

    return (
      <React.Fragment>
        <p>Only change transaction status to refund.</p>
        <ConfirmPopup />
        <Button loading={isLoading} type="button" onClick={confirmRequest} label="Refund" />
      </React.Fragment>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: "0px" }} className="card grid col-12 mx-auto">
      <div className="col-12">
        <Toolbar className="mb-4 w-full" left={leftToolbar} />
      </div>
      <TabView
        className="col-12 p-0 field"
        activeIndex={currentTabIndex}
        onTabChange={(e) => {
          setCurrentTabIndex(e.index);
        }}
      >
        <TabPanel header="Order">
          <div className="field col-12 lg:col-12">
            <label htmlFor="sku">Invoice Number : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="transaction_id"
                render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
              />
            </div>
          </div>
          <div className="field col-12 ">
            <label htmlFor="category_id">Order Status : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="status"
                render={({ field }) => (
                  <Dropdown
                    ref={field.ref}
                    optionLabel="label"
                    optionValue="value"
                    value={field.value}
                    onBlur={field.onBlur}
                    options={TransactionStatus.map((d, index) => ({ label: d, value: index }))}
                    className={classNames("w-full capitalize")}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    placeholder="Choose option"
                  />
                )}
              />
            </div>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="sku">Order Date : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="created_at"
                render={({ field }) => <InputText disabled value={NewTimeFormatter(field.value)} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
              />
            </div>
          </div>

          <h4 className="col-12">Discount</h4>
          <div className="field col-12 lg:col-12">
            <label htmlFor="sku">Discount Title : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="discount.title"
                render={({ field }) => <InputText disabled value={field.value || "-"} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
              />
            </div>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="sku">Discount Code : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="discount.code"
                render={({ field }) => <InputText disabled value={field.value || "-"} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
              />
            </div>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="sku">Discount Type : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="discount.type"
                render={({ field }) => <InputText disabled value={discountType.find((d) => d.id === field.value)?.label || "-"} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
              />
            </div>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="sku">Discount : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="discount.price"
                render={({ field }) => <InputNumber mode="currency" currency="USD" disabled value={field.value || 0} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
              />
            </div>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="sku">Shipping Discount : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="discount.shipping_price"
                render={({ field }) => <InputNumber mode="currency" currency="USD" disabled value={field.value || 0} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Transaction Address">
          <section className="w-full grid col-12">
            <div className="col-12">
              <p className="font-bold uppercase">Shipping Address</p>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Name : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="receiver_address.name"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="user_name">Reciever Name : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="receiver_address.user_name"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Country: </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="receiver_address.country"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Province : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="receiver_address.province"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">District : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="receiver_address.district"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Urban : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="receiver_address.urban"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">City : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="receiver_address.city"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Postal Code : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="receiver_address.postal_code"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Phone Number : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="receiver_address.phone_number"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Email : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="user.email"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Country : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="receiver_address.country"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Code : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="receiver_address.code"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12">
              <label htmlFor="name">Address : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="receiver_address.address"
                  render={({ field }) => <InputTextarea rows={4} disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
          </section>
        </TabPanel>
        <TabPanel header="Products" className="">
          <p className="px-3">
            <span className="font-bold">PRODUCTS LIST</span>
          </p>
          <DataTable emptyMessage="No data." value={transactionData?.transaction_details}>
            <Column
              body={(data) => (
                <div style={{ maxWidth: "80px", maxHeight: "80px", overflow: "hidden" }}>
                  <Image preview imageClassName="object-contain w-full h-full" className="w-full h-full" src={data?.product?.images[0]?.url} alt="image" />
                </div>
              )}
              headerStyle={{ width: "150px", minWidth: "80px" }}
            ></Column>
            <Column field="product.name" header="Product Name"></Column>
            <Column
              field="variant_combinations"
              header="Variant"
              body={(d) => {
                let variantOne = d?.variant_combinations?.variant_detail_one?.name;
                let variantTwo = d?.variant_combinations?.variant_detail_two?.name;

                let displayTitle = [];

                if (variantOne) displayTitle.push(variantOne);
                if (variantTwo) displayTitle.push(variantTwo);

                return displayTitle.join(" - ") || "-";
              }}
            ></Column>
            <Column field="qty" header="Qty"></Column>
            <Column field="total_price" header="Price" body={(d) => priceFormatter(d.total_price)}></Column>
            <Column headerStyle={{ width: "4rem" }}></Column>
          </DataTable>
        </TabPanel>
        <TabPanel header="Shipping">
          <div className="field col-12 ">
            <label htmlFor="category_id">Shipping Status : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="shipping_status"
                render={({ field }) => (
                  <Dropdown
                    disabled
                    ref={field.ref}
                    optionLabel="label"
                    optionValue="value"
                    value={field.value}
                    onBlur={field.onBlur}
                    options={TransactionStatus.map((d, index) => ({ label: d, value: index }))}
                    className={classNames("w-full capitalize")}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    placeholder="Choose option"
                  />
                )}
              />
            </div>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="sku">Courier : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="courier.name"
                render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
              />
            </div>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="sku">Service : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="shipping_type"
                render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
              />
            </div>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="sku">AWB : </label>
            <div className="w-full">
              <Controller rules={{ required: true }} control={control} defaultValue={""} name="resi" render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />} />
            </div>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="sku">Shipping Price : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="shipping_price"
                render={({ field }) => <InputNumber mode="currency" currency="USD" disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
              />
            </div>
          </div>

          <div className="col-12 card">
            <h4 className="text-center mb-4">Shipping History</h4>
            {trackHistory.length ? (
              <Timeline
                value={trackHistory}
                opposite={(item) => <small className="text-color-secondary">{TimeFormatter(item.date)}</small>}
                content={(item) => (
                  <span className="block" style={{ maxWidth: "400px" }}>
                    {item.description}
                  </span>
                )}
              />
            ) : (
              <p>No History available.</p>
            )}
          </div>
        </TabPanel>
        <TabPanel header="Refund">
          <div className="col-12">
            <RequestRefund />
          </div>
        </TabPanel>
      </TabView>
      <div className="flex justify-content-center mt-4 w-full">
        {currentTabIndex === 0 ? <Button label="Save" loading={updateLoading} className=" p-button-primary mr-4" /> : null}
        <Link to="/dashboard/transaction-list">
          <Button type="button" label="Back" className=" p-button-secondary" />
        </Link>
      </div>
    </form>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(TransactionListUpdate, comparisonFn);
