// hooks
import React, { useEffect } from "react";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// conponents
import toast from "react-hot-toast";
import ReactImageUploading from "react-images-uploading";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import { InputSwitch } from "primereact/inputswitch";
import { Checkbox } from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";

// utils
import classNames from "classnames";
import basicJodithConfig from "../../config/basicJodithConfig";
import JoditEditor from "jodit-react";
import productSkinTypes from "../../assets/data/product_skin_type.json";

// api related
import Api from "../../api/Api";
import { useState } from "react";
import discountFormatter from "../../utils/discountFormatter";

const ProductCreate = () => {
  const navigate = useNavigate();
  const [isVariant, setIsVariant] = useState(false);
  const [variantCombination, setVariantCombination] = useState([]);
  const [images, setImages] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);

  // hooks
  const { control, handleSubmit, formState, getValues, reset, watch } = useForm();
  const category_watch = watch("category_ids");
  const {
    fields: variantOneFields,
    remove: variantOneRemove,
    append: variantOneAppend,
  } = useFieldArray({
    control,
    name: "variants",
  });
  const variants_watch = useWatch({
    control,
    name: "variants",
  });

  // query
  const { data: productOption } = useQuery("product", () => getProduct());
  const { data: productClaimsOptions } = useQuery("product-claims", () => getProductClaims());
  const { data: catData } = useQuery("product-category", () => getProductCategory(), { initialData: [] });
  const { data: variantsOptions } = useQuery("variants-options", () => getVariantOptions(), { initialData: [] });
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("product", data), {
    onSettled: (response) => {
      if (response.data.status === 200) {
        navigate("/dashboard/product");
        toast.success("Product created!");
      } else {
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // functions
  const getProduct = async () => {
    try {
      const res = await Api().get("/product?page=1&limit=999999");

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const getProductClaims = async () => {
    try {
      const res = await Api().get("/product-claim?page=1&limit=999999");

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const getVariantOptions = async () => {
    try {
      const res = await Api().get("/product-variant?page=1&limit=999999");
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getProductCategory = async () => {
    try {
      const res = await Api().get("/category?page=1&limit=999999");
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      const results = res.data.data.filter((cat) => cat.tag === 0 && !cat.parent_id);
      let temp_result = [];

      results.forEach((parent) => {
        temp_result.push({ id: parent.id, name: parent.name });
        for (let i = 0; i < parent.child_categories.length; i++) {
          if (parent.child_categories[i].tag === 0) {
            temp_result.push({ id: parent.child_categories[i].id, name: parent.child_categories[i].full_name });
          }
        }
      });

      setCategoryOptions(temp_result);
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
    }
  };
  const onChangeImage = (imageList) => {
    setImages(imageList);
  };

  const onSubmit = (data) => {
    data.active_status = data.active_status ? 1 : 0;
    delete data.variants;
    let temp_variant = [];

    if (data.discount_percentage > 0) {
      data.price_promo = discountFormatter.calculateDiscount(data.price, data.discount_percentage);
    } else {
      data.price_promo = 0;
    }

    if (variantCombination.length) {
      variantCombination.forEach((v) => {
        let promo_price = 0;

        if (v.discount_percentage > 0) {
          promo_price = discountFormatter.calculateDiscount(v.price, v.discount_percentage);
        } else {
          promo_price = 0;
        }

        temp_variant.push({ product_variant_detail_id_one: v.product_variant_detail_id_one, product_variant_detail_id_two: v.product_variant_detail_id_two, price: v.price, price_promo: promo_price, qty: v.qty, index_photo: v.index_photo, sku: v.sku, other_code: v.other_code, barcode: v.barcode });
      });
    }

    data.variant_combinations = JSON.stringify(temp_variant);

    let selected_category_ids = data.category_ids || [];
    let selected_tags = data.tags || [];

    const category_ids = [...selected_category_ids, ...selected_tags];

    if (category_ids?.length) {
      data.category_ids = category_ids.join(",");
    }

    if (data.related) {
      data.related = data.related.join(",");
    }

    if (data.skin_type?.length) {
      data.skin_type = data.skin_type.join(",");
    }

    if (data.for_you) {
      data.for_you = data.for_you.join(",");
    }

    if (data.product_claim_ids) {
      data.product_claim_ids = data.product_claim_ids.join(",");
    }

    const formData = new FormData();
    let file_key = ["files"];

    // if data is array then its gonna looop
    let array_key = ["files"];

    if (!images.length) return toast.error("Please Upload Images");
    if (images.length) {
      let temp_image = [];
      for (let i = 0; i < images.length; i++) {
        temp_image.push(images[i].file);
      }
      data.files = temp_image;
    }

    if (!data.related?.length) {
      delete data.related;
    }

    // clean up
    Object.keys(data).forEach((key) => {
      const isUndifined = data[key] === undefined || data[key] === null;
      if (isUndifined) {
        delete data[key];
      }
    });

    for (const key in data) {
      let isFile = file_key.find((data) => data === key);
      let isArrayKey = array_key.find((data) => data === key);

      if (isFile && isArrayKey) {
        for (let i = 0; i < data[isArrayKey].length; i++) {
          formData.append(key, data[isArrayKey][i]);
        }
      }

      if (isFile && !isArrayKey) {
        formData.append(key, data[key][0]);
      }

      if (!isFile && isArrayKey) {
        for (let i = 0; i < data[isArrayKey].length; i++) {
          formData.append(key, data[isArrayKey][i]);
        }
      }

      if (!isFile && !isArrayKey) {
        formData.append(key, data[key]);
      }
    }

    crateMutate(formData);
  };

  const hanldeErrorImageUpload = (error) => {
    if (error?.acceptType) {
      toast.error("format image should jpg, jpeg, png, gif, webp");
    }

    if (error?.maxFileSize) {
      toast.error("File Size Max 1MB");
    }
    if (error?.maxNumber) {
      toast.error("max file is 8 images");
    }
  };

  // components
  const productSelectTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img width={50} alt={option.name} src={option?.images[0]?.url} />
        <div className="ml-2">{option.name}</div>
      </div>
    );
  };

  const productClaimsTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img width={50} alt={option.name} src={option?.url} />
        <div className="ml-2">{option.name}</div>
      </div>
    );
  };

  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Create Product
          </h4>
        </div>
      </React.Fragment>
    );
  };

  useEffect(() => {
    if (variants_watch) {
      let filtred_variant = variants_watch.filter((v) => v.type && v.name);

      let color_variant = filtred_variant.find((v) => v.type === "Color")?.name || [];
      let size_variant = filtred_variant.find((v) => v.type === "Size")?.name || [];

      let first_array = color_variant.length >= size_variant.length ? color_variant : size_variant;
      let second_array = color_variant.length < size_variant.length ? color_variant : size_variant;

      // mapping for variant color
      let temp_variant_field_color = {
        type: "Color",
        detail: [],
      };

      if (color_variant.length) {
        for (let i = 0; i < color_variant.length; i++) {
          temp_variant_field_color.detail.push({ id: color_variant[i]?.name?.id, name: color_variant[i]?.name?.name });
        }
      }

      // mapping for variant size
      let temp_variant_field_size = {
        type: "Size",
        detail: [],
      };

      if (size_variant.length) {
        for (let i = 0; i < size_variant.length; i++) {
          temp_variant_field_size.detail.push({ id: size_variant[i]?.name?.id, name: size_variant[i]?.name?.name });
        }
      }

      let array_comb = [];

      for (var i = 0; i < first_array.length; i++) {
        if (second_array.length) {
          for (var j = 0; j < second_array.length; j++) {
            array_comb.push({
              id: i + 1 + j * 120,
              product_variant_detail_id_one: first_array[i]?.id,
              product_variant_detail_id_two: second_array[j]?.id,
              variant1: first_array[i]?.name,
              variant2: second_array[j]?.name,
              sku: "",
              price: 0,
              price_promo: 0,
              qty: 0,
              index_photo: 0,
            });
          }
        } else {
          array_comb.push({
            id: i + 1 * 120,
            product_variant_detail_id_one: first_array[i]?.id,
            variant1: first_array[i]?.name,
            price: 0,
            price_promo: 0,
            qty: 0,
            index_photo: 0,
          });
        }
      }
      setVariantCombination(array_comb);
    }
  }, [variants_watch]);

  useEffect(() => {
    if (category_watch && catData.length) {
      let parents_id = [];

      category_watch.forEach((category) => {
        const selected_category = catData.find((cat_d) => cat_d.id === category);

        if (selected_category && selected_category.parent_id) {
          const isAlreadyExist = parents_id.find((p) => p === selected_category.parent_id);
          if (!isAlreadyExist) {
            parents_id.push(selected_category.parent_id);
          }
        }
        if (selected_category && !selected_category.parent_id) {
          const isAlreadyExist = parents_id.find((p) => p === selected_category.id);
          if (!isAlreadyExist) {
            parents_id.push(selected_category.id);
          }
        }
      });

      let temp_tags = [];

      parents_id.forEach((id) => {
        const finding = catData.find((c) => c.id === id);
        if (finding) {
          const filtered_tag = finding.child_categories.filter((f) => f.tag === 1);
          temp_tags = [...temp_tags, ...filtered_tag];
        }
      });

      if (temp_tags.length) {
        setTagOptions(temp_tags);
      } else {
        setTagOptions([]);
      }
    }
  }, [category_watch, catData]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: "0px" }} className="card grid col-12 mx-auto">
        <div className="col-12">
          <Toolbar className="mb-4 w-full" left={leftToolbar} />
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Product Name : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="name"
              render={({ field }) => (
                <InputText
                  placeholder="Input product name"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.name,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.name && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="sku">Product SKU : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="sku"
              render={({ field }) => (
                <InputText
                  placeholder="Input product sku"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.sku,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.sku && (
              <small id="sku" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="barcode">Barcode : </label>
          <div className="w-full">
            <Controller
              rules={{ required: false }}
              control={control}
              defaultValue={""}
              name="barcode"
              render={({ field }) => <InputText placeholder="Input barcode" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="barcode" type="text" className={classNames("w-full")} />}
            />
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="other_code">Odo Code : </label>
          <div className="w-full">
            <Controller
              rules={{ required: false }}
              control={control}
              defaultValue={""}
              name="other_code"
              render={({ field }) => <InputText placeholder="Input code" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="other_code" type="text" className={classNames("w-full")} />}
            />
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="category_ids">Category : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="category_ids"
              rules={{ required: true }}
              render={({ field }) => (
                <MultiSelect
                  filter
                  display="chip"
                  className={classNames({ "p-invalid": formState.errors?.category_ids }, "w-full")}
                  emptyFilterMessage="no data found"
                  disabled={!catData?.length}
                  value={field.value}
                  options={categoryOptions}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Select category"
                />
              )}
            />
            {formState.errors?.category_ids && (
              <small id="category_ids" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="tags">Tags : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="tags"
              rules={{ required: false }}
              render={({ field }) => (
                <MultiSelect
                  filter
                  display="chip"
                  className={classNames({ "p-invalid": formState.errors?.tags }, "w-full")}
                  emptyFilterMessage="no data found"
                  disabled={!tagOptions?.length}
                  value={field.value}
                  options={tagOptions}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Select category"
                />
              )}
            />
            {formState.errors?.tags && (
              <small id="tags" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="skin_type">Skin Type : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="skin_type"
              rules={{ required: false }}
              render={({ field }) => (
                <MultiSelect
                  className={classNames({ "p-invalid": formState.errors?.skin_type }, "w-full")}
                  emptyFilterMessage="no data found"
                  value={field.value}
                  options={productSkinTypes}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  display="chip"
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Select category"
                />
              )}
            />
            {formState.errors?.skin_type && (
              <small id="skin_type" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="related">Related Product : </label>
          <div className="w-full">
            <Controller
              control={control}
              defaultValue={[]}
              name="related"
              render={({ field }) => (
                <MultiSelect
                  filter
                  className="w-full"
                  emptyFilterMessage="no data found"
                  disabled={!productOption?.length}
                  value={field.value}
                  options={productOption}
                  onChange={(e) => field.onChange(e)}
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Select related product"
                  display="chip"
                  itemTemplate={productSelectTemplate}
                />
              )}
            />
            {formState.errors?.related && (
              <small id="related" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="for_you">Product For You : </label>
          <div className="w-full">
            <Controller
              control={control}
              defaultValue={[]}
              name="for_you"
              render={({ field }) => (
                <MultiSelect
                  filter
                  className="w-full"
                  emptyFilterMessage="no data found"
                  disabled={!productOption?.length}
                  value={field.value}
                  options={productOption}
                  onChange={(e) => field.onChange(e)}
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Select product for you"
                  display="chip"
                  itemTemplate={productSelectTemplate}
                />
              )}
            />
            {formState.errors?.for_you && (
              <small id="for_you" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="product_claim_ids">Product Claims : </label>
          <div className="w-full">
            <Controller
              control={control}
              defaultValue={[]}
              name="product_claim_ids"
              render={({ field }) => (
                <MultiSelect
                  filter
                  className="w-full"
                  emptyFilterMessage="no data found"
                  disabled={!productClaimsOptions?.length}
                  value={field.value}
                  options={productClaimsOptions}
                  onChange={(e) => field.onChange(e)}
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Select product claims"
                  display="chip"
                  itemTemplate={productClaimsTemplate}
                />
              )}
            />
            {formState.errors?.product_claim_ids && (
              <small id="product_claim_ids" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="order">Product Order (Position) : </label>
          <Controller
            defaultValue={0}
            control={control}
            name="order"
            render={({ field }) => <InputNumber className={classNames({ "p-invalid": formState.errors?.order }, "w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />}
          />
          {formState.errors?.order && (
            <small id="order" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>
        <div className="field col-12">
          <label htmlFor="meta_title">Product Active Status : </label>
          <div className="w-full">
            <Controller control={control} defaultValue={false} name="active_status" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div>
        </div>

        <div className="col-12">
          <p className="font-bold uppercase">Product Pricing and other</p>
        </div>

        {/* price */}
        <div className="field col-12">
          <label htmlFor="price">Price (USD) : </label>
          <Controller
            defaultValue={0}
            control={control}
            name="price"
            render={({ field }) => <InputNumber className={classNames({ "p-invalid": formState.errors?.price }, "w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="currency" currency="USD" locale="en-US" placeholder="0" />}
          />
          {formState.errors?.price && (
            <small id="price" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>
        <div className="field col-12">
          <label htmlFor="discount_percentage">Discount Percentage (%) : </label>
          <Controller
            defaultValue={0}
            control={control}
            name="discount_percentage"
            render={({ field }) => <InputNumber suffix="%" min={0} max={100} className={classNames({ "p-invalid": formState.errors?.discount_percentage }, "w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons placeholder="0" />}
          />
          {formState.errors?.discount_percentage && (
            <small id="price" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>
        {/* <div className="field col-12">
          <label htmlFor="price_promo">Discount Price (USD) : </label>
          <Controller
            defaultValue={0}
            control={control}
            name="price_promo"
            render={({ field }) => (
              <InputNumber className={classNames({ "p-invalid": formState.errors?.price_promo }, "w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="currency" currency="USD" locale="en-US" placeholder="0" />
            )}
          />
          {formState.errors?.price_promo && (
            <small id="price" className="p-error block pt-1">
              field required
            </small>
          )}
        </div> */}

        {/* qty */}
        <div className="field col-12">
          <label htmlFor="qty">Available Stock : </label>
          <Controller
            defaultValue={0}
            control={control}
            name="qty"
            render={({ field }) => <InputNumber className={classNames({ "p-invalid": formState.errors?.qty }, "w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />}
          />
          {formState.errors?.qty && (
            <small id="qty" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>
        <div className="field col-12">
          <label htmlFor="qty_alert">Min Safety Stock : </label>
          <Controller
            defaultValue={0}
            control={control}
            name="qty_alert"
            render={({ field }) => <InputNumber className={classNames({ "p-invalid": formState.errors?.qty_alert }, "w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />}
          />
          {formState.errors?.qty_alert && (
            <small id="qty_alert" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>

        {/* weight */}
        <div className="field col-12">
          <label htmlFor="weight">Weight (gram) : </label>
          <div className="w-full">
            <Controller defaultValue={0} control={control} name="weight" render={({ field }) => <InputNumber className="w-full" onBlur={field.onBlur} ref={field.ref} value={field.value} mode="decimal" minFractionDigits={1} onValueChange={(e) => field.onChange(e)} showButtons placeholder="0" />} />
            {formState.errors?.weight && (
              <small id="weight" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>

        {/* width */}
        <div className="field col-12">
          <label htmlFor="width">Width (in mm) : </label>
          <div className="w-full">
            <Controller defaultValue={0} control={control} name="width" render={({ field }) => <InputNumber className="w-full" onBlur={field.onBlur} ref={field.ref} mode="decimal" minFractionDigits={1} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons placeholder="0" />} />
            {formState.errors?.width && (
              <small id="width" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>

        {/* height */}
        <div className="field col-12">
          <label htmlFor="height">Height (in mm) : </label>
          <div className="w-full">
            <Controller defaultValue={0} control={control} name="height" render={({ field }) => <InputNumber className="w-full" onBlur={field.onBlur} ref={field.ref} mode="decimal" minFractionDigits={1} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons placeholder="0" />} />
            {formState.errors?.height && (
              <small id="height" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>

        {/* length */}
        <div className="field col-12">
          <label htmlFor="length">Length (in mm) : </label>
          <div className="w-full">
            <Controller defaultValue={0} control={control} name="length" render={({ field }) => <InputNumber className="w-full" onBlur={field.onBlur} ref={field.ref} mode="decimal" minFractionDigits={1} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons placeholder="0" />} />
            {formState.errors?.length && (
              <small id="length" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>

        <div className="col-12">
          <p className="font-bold uppercase">Product Descriptions</p>
        </div>

        {/* small text */}
        <div className="field col-12">
          <label htmlFor="short_description">Small Text : </label>
          <div className="w-full">
            <Controller
              rules={{ required: false }}
              control={control}
              name="short_description"
              render={({ field }) => (
                <JoditEditor
                  ref={field.ref}
                  value={field.value || ""}
                  config={basicJodithConfig}
                  tabIndex={1}
                  onBlur={(e) => {
                    field.onBlur(e);
                  }}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                />
              )}
            />
            {formState.errors?.short_description && (
              <small id="short_description" className="p-error block pt-1">
                filed required
              </small>
            )}
          </div>
        </div>

        {/* Descriptions */}
        <div className="field col-12">
          <label htmlFor="info1">Description : </label>
          <div className="w-full">
            <Controller
              rules={{ required: false }}
              control={control}
              name="info1"
              render={({ field }) => (
                <JoditEditor
                  ref={field.ref}
                  value={field.value || ""}
                  config={basicJodithConfig}
                  tabIndex={1}
                  onBlur={(e) => {
                    field.onBlur(e);
                  }}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                />
              )}
            />
            {formState.errors?.info1 && (
              <small id="description" className="p-error block pt-1">
                filed required
              </small>
            )}
          </div>
        </div>

        {/* how to use */}
        <div className="field col-12">
          <label htmlFor="info2">How to use : </label>
          <div className="w-full">
            <Controller
              rules={{ required: false }}
              control={control}
              name="info2"
              render={({ field }) => (
                <JoditEditor
                  ref={field.ref}
                  value={field.value || ""}
                  config={basicJodithConfig}
                  tabIndex={1}
                  onBlur={(e) => {
                    field.onBlur(e);
                  }}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                />
              )}
            />
            {formState.errors?.info2 && (
              <small id="info2" className="p-error block pt-1">
                filed required
              </small>
            )}
          </div>
        </div>

        {/* Ingredients*/}
        <div className="field col-12">
          <label htmlFor="info3">Ingredients : </label>
          <div className="w-full">
            <Controller
              rules={{ required: false }}
              control={control}
              name="info3"
              render={({ field }) => (
                <JoditEditor
                  ref={field.ref}
                  value={field.value || ""}
                  config={basicJodithConfig}
                  tabIndex={1}
                  onBlur={(e) => {
                    field.onBlur(e);
                  }}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                />
              )}
            />

            {formState.errors?.info3 && (
              <small id="info3" className="p-error block pt-1">
                filed required
              </small>
            )}
          </div>
        </div>

        {/* images */}
        <section style={{ borderRadius: 0 }} className="field col-12 ">
          <label htmlFor="">Upload Images Max 1MB / Image: </label>
          <ReactImageUploading style={{ width: "100%" }} maxNumber={8} maxFileSize={1000000} onError={hanldeErrorImageUpload} multiple value={images} onChange={onChangeImage} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg", "gif", "webp"]}>
            {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
              <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                <div className="flex justify-content-center w-full">
                  <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                </div>

                {imageList.length ? (
                  <div className="grid mt-4 w-full">
                    {imageList.map((image, index) => (
                      <div key={index} className="col-4 lg:col-2 relative">
                        <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                        <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                        <div className="flex" style={{ gap: "10px" }}>
                          <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          </ReactImageUploading>
        </section>

        {/* variants */}
        <div className="col-12 field flex align-items-center">
          <Checkbox
            onChange={(e) => {
              setIsVariant(e.checked);
              if (e.checked) variantOneAppend("");
            }}
            checked={isVariant}
          ></Checkbox>
          <p className="font-bold uppercase ml-2">Use Product Variants</p>
        </div>

        {/* variants details */}
        {isVariant ? (
          <>
            {/* variant input */}
            {variantOneFields.map((item, idx) => (
              <div key={item.id} className="grid col-12 mx-auto p-0">
                <div className="field col-4">
                  <label htmlFor={`variants[${idx}].type`}>Variant Type ({idx + 1}) : </label>
                  <div className="w-full">
                    <Controller
                      rules={{ required: true }}
                      control={control}
                      defaultValue={""}
                      name={`variants[${idx}].type`}
                      render={({ field }) => (
                        <Dropdown
                          inputRef={field.ref}
                          optionLabel="name"
                          optionValue="name"
                          value={field.value}
                          filter
                          onBlur={field.onBlur}
                          options={variants_watch && variantsOptions && variantsOptions?.length && idx > 0 && variants_watch[0]?.type ? variantsOptions.filter((v) => v.name !== variants_watch[0]?.type) : variantsOptions}
                          className="w-full"
                          onChange={(e) => {
                            field.onChange(e);
                            let temp = getValues();
                            temp.variants[idx].name = [];
                            reset(temp);
                          }}
                          placeholder="Select Variant"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="field col-3">
                  <label htmlFor={`variants[${idx}].name`}>Variant Value : </label>
                  <div className="w-full">
                    <Controller
                      control={control}
                      name={`variants[${idx}].name`}
                      render={({ field }) => (
                        <MultiSelect
                          className="w-full"
                          onBlur={field.onBlur}
                          ref={field.ref}
                          id="category_id"
                          value={field.value}
                          filter
                          onChange={(e) => {
                            field.onChange(e);
                            // let temp = getValues();
                            // temp.variants[idx].name = [];
                            // reset(temp);
                          }}
                          disabled={!variants_watch || !variantsOptions.length}
                          options={variants_watch && variants_watch[idx]?.type ? variantsOptions.find((v) => v.name === variants_watch[idx].type).variant_details : []}
                          emptyFilterMessage="No option available"
                          optionLabel="name"
                          placeholder="Select Variant Value"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="field col-2 mt-auto">
                  <Button
                    type="button"
                    className="p-button-danger w-full"
                    icon="pi pi-trash"
                    onClick={() => {
                      variantOneRemove(idx);
                    }}
                  />
                </div>
              </div>
            ))}

            <div className="field col-12">
              <Button
                onClick={() => {
                  variantOneAppend("");
                }}
                type="button"
                label="Add Variant"
              />
            </div>

            {/* variant table */}
            {variantCombination.length ? (
              <>
                <div className="col-12 mb-4">
                  <p className="font-bold uppercase">Product Table</p>
                </div>
                <div style={{ borderTop: "1px solid black", borderBottom: "1px solid black" }} className="grid col-12 mx-auto py-3 mb-5">
                  <div className="col-2">Variant</div>
                  <div className="col-1">SKU</div>
                  <div className="col-1">Barcode</div>
                  <div className="col-1">Odo Code</div>
                  <div className="col-2">Price</div>
                  <div className="col-2">Discount Percentage (%)</div>
                  <div className="col-2">Available Stock</div>
                  <div className="col-1">Index Picture</div>
                </div>
                {variantCombination.map((item, idx) => (
                  <div key={idx * 120} className="grid col-12 mx-auto">
                    <div className="field col-2">
                      {item?.variant1} {item?.variant2 ? `- ${item.variant2}` : ""}
                    </div>
                    <div className="field col-1">
                      <InputText
                        className="w-full"
                        placeholder="Input SKU"
                        value={item.sku}
                        onChange={(e) => {
                          item.sku = e.target.value;
                          setVariantCombination([...variantCombination]);
                        }}
                        id="name"
                      />
                    </div>
                    <div className="field col-1">
                      <InputText
                        className="w-full"
                        placeholder="Input barcode"
                        value={item.barcode}
                        onChange={(e) => {
                          item.barcode = e.target.value;
                          setVariantCombination([...variantCombination]);
                        }}
                        id="name"
                      />
                    </div>
                    <div className="field col-1">
                      <InputText
                        className="w-full"
                        placeholder="Input Odo code"
                        value={item.other_code}
                        onChange={(e) => {
                          item.other_code = e.target.value;
                          setVariantCombination([...variantCombination]);
                        }}
                        id="name"
                      />
                    </div>
                    <div className="field col-2">
                      <InputNumber
                        className="w-full"
                        inputClassName="w-full"
                        showButtons
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        placeholder="Input variant value depend on variant type"
                        value={item.price}
                        onChange={(e) => {
                          item.price = e.value;
                          setVariantCombination([...variantCombination]);
                        }}
                        id="name"
                      />
                    </div>
                    <div className="field col-2">
                      <InputNumber
                        className="w-full"
                        inputClassName="w-full"
                        showButtons
                        placeholder="Input variant value depend on variant type"
                        value={item.discount_percentage}
                        suffix="%"
                        min={0}
                        max={100}
                        onChange={(e) => {
                          item.discount_percentage = e.value;
                          setVariantCombination([...variantCombination]);
                        }}
                        id="name"
                      />
                    </div>
                    <div className="field col-2">
                      <InputNumber
                        className="w-full"
                        inputClassName="w-full"
                        showButtons
                        placeholder="Input variant value depend on variant type"
                        value={item.qty}
                        onChange={(e) => {
                          item.qty = e.value;
                          setVariantCombination([...variantCombination]);
                        }}
                        id="name"
                      />
                    </div>
                    <div className="field col-1">
                      <Dropdown
                        value={item.index_photo}
                        onChange={(e) => {
                          item.index_photo = e.value;
                          setVariantCombination([...variantCombination]);
                        }}
                        options={images.map((data, index) => ({ label: data.data_url, value: index }))}
                        optionLabel="label"
                        placeholder="Select a City"
                        className="w-full"
                        valueTemplate={(option, props) => {
                          if (option) {
                            return (
                              <div className="flex align-items-center">
                                <img style={{ height: "18px" }} alt={option.name} src={option.label} />
                              </div>
                            );
                          } else {
                            return <span>{props.placeholder}</span>;
                          }
                        }}
                        itemTemplate={(option) => {
                          return (
                            <div className="flex align-items-center">
                              <img style={{ width: "50px" }} alt={option.name} src={option.label} />
                            </div>
                          );
                        }}
                      />
                    </div>
                  </div>
                ))}
              </>
            ) : null}
          </>
        ) : null}

        <div className="flex justify-content-center mt-4 w-full">
          <Button label="Save" loading={createLoading} className=" p-button-primary mr-4" />
          <Link to="/dashboard/product">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(ProductCreate, comparisonFn);
