import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames";
import { Ripple } from "primereact/ripple";
import { Badge } from "primereact/badge";
import { Accordion, AccordionTab } from "primereact/accordion";
import logo from "../assets/images/logo.png";

const AppSubmenu = (props) => {
  let activeClassName = "router-link-active router-link-exact-active p-ripple";
  const [activeIndex, setActiveIndex] = useState(null);

  const onMenuItemClick = (event, item, index) => {
    //avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    //execute command
    if (item.command) {
      item.command({ originalEvent: event, item: item });
    }

    if (index === activeIndex) setActiveIndex(null);
    else setActiveIndex(index);

    if (props.onMenuItemClick) {
      props.onMenuItemClick({
        originalEvent: event,
        item: item,
      });
    }
  };

  const onKeyDown = (event) => {
    if (event.code === "Enter" || event.code === "Space") {
      event.preventDefault();
      event.target.click();
    }
  };

  const renderLinkContent = (item) => {
    let submenuIcon = item.items && <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>;
    let badge = item.badge && <Badge value={item.badge} />;

    return (
      <React.Fragment>
        {/* <i className={item.icon}></i> */}
        <i className="fa-solid fa-caret-right"></i>
        <span>{item.label}</span>
        {submenuIcon}
        {badge}
        <Ripple />
      </React.Fragment>
    );
  };

  const renderLink = (item, i) => {
    let content = renderLinkContent(item);

    if (item.to) {
      return (
        <NavLink aria-label={item.label} onKeyDown={onKeyDown} role="menuitem" end className={({ isActive }) => (isActive ? activeClassName : "p-ripple")} to={item.to} onClick={(e) => onMenuItemClick(e, item, i)} target={item.target}>
          {content}
        </NavLink>
      );
    } else {
      return (
        <a tabIndex="0" aria-label={item.label} onKeyDown={onKeyDown} role="menuitem" href={item.url} className="p-ripple" onClick={(e) => onMenuItemClick(e, item, i)} target={item.target}>
          {content}
        </a>
      );
    }
  };

  let items =
    props.items &&
    props.items.map((item, i) => {
      let active = activeIndex === i;
      let styleClass = classNames(item.badgeStyleClass, { "layout-menuitem-category": props.root, "active-menuitem": active && !item.to });

      if (props.root) {
        return (
          <li className={styleClass} key={i} role="none">
            {props.root === true && (
              <React.Fragment>
                <div className="layout-menuitem-root-text" aria-label={item.label}>
                  {item.label}
                </div>
                <AppSubmenu items={item.items} onMenuItemClick={props.onMenuItemClick} />
              </React.Fragment>
            )}
          </li>
        );
      } else {
        return (
          <li className={styleClass} key={i} role="none">
            {renderLink(item, i)}
            <CSSTransition classNames="layout-submenu-wrapper" timeout={{ enter: 1000, exit: 450 }} in={active} unmountOnExit>
              <AppSubmenu items={item.items} onMenuItemClick={props.onMenuItemClick} />
            </CSSTransition>
          </li>
        );
      }
    });

  return items ? (
    <ul className={props.className} role="menu">
      {items}
    </ul>
  ) : null;
};

const AppMenuItem = ({ item, activeRoute, setActiveRoute }) => {
  return (
    <NavLink
      onClick={() => {
        setActiveRoute(item.to);
      }}
      className={classNames(
        {
          "menu-item-active": activeRoute === item.to,
        },
        "flex align-items-center menu-item"
      )}
      role="menuitem"
      to={item.to}
    >
      <i class="fa-solid fa-circle" style={{ fontSize: "5px" }}></i>
      <span style={{ marginLeft: "8px" }}>{item.label}</span>
    </NavLink>
  );
};

export const AppMenu = (props) => {
  const location = useLocation();
  const [activeRoute, setActiveRoute] = useState(location.pathname);

  // <div className="layout-menu-container">
  //   <AppSubmenu items={props.model} className="layout-menu" onMenuItemClick={props.onMenuItemClick} root={true} role="menu" />
  // </div>
  return (
    <div className="layout-menu-container">
      <div className="menu-logo">
        <Link to="/">
          <div className="align-items-center flex justify-content-center h-2rem md:h-3rem mb-4">
            <img width={120} src={logo} alt="logo" />
          </div>
        </Link>
      </div>
      <Accordion>
        {props.model.map((menu) => (
          <AccordionTab className="menu" header={menu.label} key={menu.label}>
            {menu.items.map((item, i) => (
              <AppMenuItem key={item.label} index={i} item={item} activeRoute={activeRoute} setActiveRoute={setActiveRoute} />
            ))}
          </AccordionTab>
        ))}
      </Accordion>
    </div>
  );
};
