// hooks
import React, { useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
// import { DevTool } from "@hookform/devtools";
import { useMutation } from "react-query";

// conponents
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import JoditEditor from "jodit-react";
import JodithJournalConfig from "../../config/JodithJournalConfig";

// config
import Api from "../../api/Api";

const StaticPageUpdate = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { isLoading: loadingUpdate, mutate: update } = useMutation(async (data) => await Api().post("/static/edit", data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        navigate("/dashboard/static-page");
      } else {
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();
  const { fields, remove, append } = useFieldArray({
    control,
    name: "details",
  });

  // functions
  const onSubmit = (data) => {
    data.id = id;

    delete data.static_details;

    update(data);
  };

  useEffect(() => {
    append({ title: null, description: null });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const getDetails = async () => {
      const response = await Api().get("/static/detail/" + id);

      if (response.data.status !== 200) {
        throw new Error("An error has occured!");
      }

      let details = response.data.data;
      details.details = details.static_details.map((static_d) => {
        return { title: static_d.title, description: static_d.description };
      });

      delete details.static_details;
      reset(details);
    };
    getDetails();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Update Static Page
          </h4>
        </div>
      </React.Fragment>
    );
  };

  const rightToolbar = () => {
    return (
      <React.Fragment>
        <Link to="/dashboard/static-page">
          <Button type="button" label="Back" className="p-button-outlined p-button-secondary ml-4" />
        </Link>
      </React.Fragment>
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="card" style={{ borderRadius: 0 }}>
        <Toolbar className="mb-4" left={leftToolbar} right={rightToolbar} />
        <div className="p-fluid formgrid grid ">
          <div className="field col-12">
            <label htmlFor="title">Title :</label>
            <Controller
              defaultValue={""}
              rules={{ required: true }}
              control={control}
              name="title"
              render={({ field }) => <InputText id="title" placeholder="Input title" onBlur={field.onBlur} onChange={(e) => field.onChange(e)} type="text" value={field.value} className={errors.title && "p-invalid"} />}
            />
          </div>
          <div className="field col-12 md:col-12">
            <div className="field p-fluid">
              <label>Body Content : {errors.content && <span style={{ color: "#D9362B" }}>Content Required</span>}</label>
              <Controller
                rules={{ required: false }}
                control={control}
                name="content"
                render={({ field }) => (
                  <JoditEditor
                    ref={field.ref}
                    value={field.value || ""}
                    config={JodithJournalConfig}
                    tabIndex={1}
                    onBlur={(e) => {
                      field.onBlur(e);
                    }}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div className="w-full pl-5">
            <h4 className="col-12">Dropdown Section</h4>
            {fields.map((item, index) => (
              <React.Fragment key={item.id}>
                <div className="col-12 formgrid grid">
                  <div className="field col-8 md:col-10">
                    <label htmlFor="title">Title :</label>
                    <Controller defaultValue={""} rules={{ required: true }} control={control} name={`details.${index}.title`} render={({ field }) => <InputText id="title" placeholder="Input title" onBlur={field.onBlur} onChange={(e) => field.onChange(e)} type="text" value={field.value} />} />
                  </div>
                  <div className="field col-4 md:col-2 mt-auto">
                    <Button
                      type="button"
                      onClick={() => {
                        remove(index);
                      }}
                      label="Remove"
                      className="p-button-danger"
                      style={{ maxWidth: "200px" }}
                    />
                  </div>
                </div>
                <div className="field col-12 md:col-12">
                  <div className="field p-fluid">
                    <label>Body Content : </label>
                    <Controller
                      rules={{ required: true }}
                      control={control}
                      name={`details.${index}.description`}
                      render={({ field }) => (
                        <JoditEditor
                          ref={field.ref}
                          value={field.value || ""}
                          config={JodithJournalConfig}
                          tabIndex={1}
                          onBlur={(e) => {
                            field.onBlur(e);
                          }}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </React.Fragment>
            ))}
            <div className="col-12">
              <Button type="button" onClick={() => append({ title: null, description: null })} label="Add Column" className="p-button-primary" style={{ maxWidth: "200px" }} />
            </div>
          </div>
          <div className="flex justify-content-center mt-4 w-full">
            <div className="flex">
              <Button loading={loadingUpdate} label="Save" className=" p-button-primary mr-4" />
              <Link to="/dashboard/static-page">
                <Button type="button" label="Back" className=" p-button-secondary" />
              </Link>
            </div>
          </div>
        </div>
      </form>
      {/* <DevTool control={control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(StaticPageUpdate, comparisonFn);
