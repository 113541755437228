const discountFormatter = {
  calculateDiscountPercentage(price, pricePromo) {
    if (!pricePromo) {
      return 0;
    }

    const discount = price - pricePromo;
    const discountPercentage = (discount / price) * 100;
    return discountPercentage;
  },
  calculateDiscount(price, discountPercentage) {
    const discount = +price * (+discountPercentage / 100);
    return price - discount;
  },
};

export default discountFormatter;
