// hooks
import React from "react";

// components
import { Document, Image, Page, Text, View } from "@react-pdf/renderer";

import LuwjistikLogo from "../../assets/images/luwjistik-logo.png";
import Logo from "../../assets/images/logo.png";
import JsBarcode from "jsbarcode";
import TransactionStatus from "../../assets/data/product_shipping_status.json";
import PriceFormatter from "../../utils/priceFormatter";

const getBarcode = (data) => {
  let canvas;
  canvas = document.createElement("canvas");
  JsBarcode(canvas, data, {
    displayValue: false,
  });
  const barcode = canvas.toDataURL();
  return barcode;
};

const Invoice = ({ values }) => {
  return (
    <Document>
      {values.map((data) => (
        <Page size="A5" style={{ padding: "20px", fontSize: "8px" }}>
          {/* container */}
          <View style={{ width: "100%", height: "100%" }}>
            <View style={{ display: "flex", flexDirection: "row", gap: "24px", justifyContent: "space-between" }}>
              <Text>Invoice {data.transaction_id}</Text>
              <View style={{ display: "flex", alignItems: "center" }}>
                <Image style={{ height: "15px", width: "95px", objectFit: "contain" }} source={Logo} />
              </View>
            </View>
            <View style={{ borderBottom: "1px solid black", paddingBottom: "10px" }}>
              <Text>
                <Text style={{ letterSpacing: "0.8px" }}>Informasi pesanan</Text>
              </Text>
            </View>
            <View style={{ width: "100%", display: "flex", marginTop: "10px", gap: "10px", flexDirection: "row" }}>
              <View style={{ width: "33,3333%", display: "flex", gap: "8px" }}>
                <View style={{ gap: "4px", display: "flex" }}>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>Order Datetime:</Text>
                  <Text>{data.payment_date}</Text>
                </View>
                <View style={{ gap: "4px", display: "flex" }}>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>Status:</Text>
                  <Text>{TransactionStatus[data.status]}</Text>
                </View>
              </View>
              <View style={{ width: "33,3333%", display: "flex", gap: "10px" }}>
                <View style={{ gap: "4px", display: "flex" }}>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>Penerima:</Text>
                  <Text>{data.receiver_address.user_name}</Text>
                  <View>
                    {/* address */}
                    <Text style={{ letterSpacing: "0.6px", lineHeight: "1.5px" }}>{data.receiver_address.address}</Text>
                    {/* phone number */}
                    <Text style={{ letterSpacing: "0.6px", lineHeight: "1.5px" }}>
                      {data.receiver_address.district}
                      {data.receiver_address.city}
                      {data.receiver_address.province} {data.receiver_address.country_data.name}, {data.receiver_address.postal_code}
                    </Text>
                    <Text>Telp. {data.receiver_address.phone_number}</Text>
                  </View>
                </View>
              </View>
              <View style={{ width: "33,3333%" }}></View>
            </View>
            <View style={{ width: "100%", display: "flex", marginTop: "10px", gap: "10px", flexDirection: "row", borderTop: "1px solid black", paddingTop: "10px" }}>
              <View style={{ width: "33,3333%", display: "flex", gap: "8px" }}>
                <View style={{ gap: "4px", display: "flex" }}>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>Metode Pengiriman:</Text>
                  <Text>{data.courier.name}</Text>
                  <Image style={{ height: "20px", width: "80px" }} source={LuwjistikLogo} />
                </View>
              </View>
              <View style={{ width: "33,3333%", display: "flex", gap: "10px" }}>
                <View style={{ gap: "4px", display: "flex" }}>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>Nomor AWB:</Text>
                  <Text>{data.resi || "-"}</Text>
                  {data.resi ? <Image style={{ height: "20px", width: "100px" }} source={getBarcode(data.resi || "-")} /> : null}
                </View>
              </View>
              <View style={{ width: "33,3333%" }}></View>
            </View>
            <View style={{ borderBottom: "1px solid black", paddingBottom: "10px", marginTop: "40px" }}>
              <Text>
                <Text style={{ letterSpacing: "0.8px" }}>Informasi Produk</Text>
              </Text>
            </View>

            <View style={{ marginTop: "20px" }}>
              {/* table */}
              <View style={{ display: "flex", gap: "10px", flexDirection: "row" }}>
                <Text style={{ width: "37%" }}>Nama Produk</Text>
                <Text style={{ width: "12%" }}>Barcode</Text>
                <Text style={{ width: "23%" }}>Harga</Text>
                <Text style={{ width: "10%" }}>Jumlah</Text>
                <Text style={{ width: "19%" }}>Subtotal</Text>
              </View>
              <View style={{ marginTop: "15px", display: "flex", gap: "10px" }}>
                {/* product */}
                {data.transaction_details.map((product) => {
                  let variants = [];
                  console.log(product);
                  let price = +product.promo_price > 0 && +product.promo_price < +product.price ? product.promo_price : product.price;

                  if (product?.variant_combinations?.variant_detail_one?.name) variants.push(product?.variant_combinations?.variant_detail_one?.name);
                  if (product?.variant_combinations?.variant_detail_two?.name) variants.push(product?.variant_combinations?.variant_detail_two?.name);

                  return (
                    <View style={{ display: "flex", gap: "10px", flexDirection: "row" }}>
                      <View style={{ width: "37%" }}>
                        <View style={{ width: "90%" }}>
                          <Text style={{ lineHeight: "1.2px" }}>
                            {product.product.name} {variants.join(" - ")}
                          </Text>
                        </View>
                      </View>
                      <Text style={{ width: "12%" }}>{product.product.barcode}</Text>
                      <Text style={{ width: "23%" }}>{PriceFormatter(+price)}</Text>
                      <Text style={{ width: "10%" }}>{product.qty} pcs</Text>
                      <Text style={{ width: "19%" }}>{PriceFormatter(product.total_price)}</Text>
                    </View>
                  );
                })}
              </View>

              <View style={{ display: "flex", marginTop: "10px", gap: "10px", flexDirection: "row" }}>
                <Text style={{ width: "37%" }}></Text>
                <Text style={{ width: "12%" }}></Text>
                <View style={{ width: "19%", display: "flex", gap: "10px" }}>
                  <Text>Subtotal (HET)</Text>
                  <Text>Diskon</Text>
                  <Text>Biaya Ongkir</Text>
                  <Text>Diskon Ongkir</Text>
                  <Text>Total Pembayaran</Text>
                </View>
                <Text style={{ width: "10%" }}></Text>
                <View style={{ width: "23%", display: "flex", gap: "10px" }}>
                  <Text>{PriceFormatter(+data.subtotal)}</Text>
                  <Text>{PriceFormatter(+data.discount_price)}</Text>
                  <Text>{PriceFormatter(+data.shipping_price)}</Text>
                  <Text>{PriceFormatter(+data.discount_shipping_price)}</Text>
                  <Text>{PriceFormatter(+data.total_payment)}</Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default Invoice;
