import Users from "./pages/Users/Users";
import Role from "./pages/Role/Role";
import Page from "./pages/PageSetting/Page";
import CreatePage from "./pages/PageSetting/CreatePage";
import CreateRole from "./pages/Role/CreateRole";
import UpdateRole from "./pages/Role/UpdateRole";
import AccountSetting from "./pages/v2/AccountSetting";
import UpdateUser from "./pages/Users/UpdateUser";
import Product from "./pages/Product/Product";
import ProductCreate from "./pages/Product/ProductCreate";
import ProductCategory from "./pages/ProductCategory/ProductCategory";
import ProductUpdate from "./pages/Product/ProductUpdate";
import Blog from "./pages/Blog/Blog";
import CreateBlog from "./pages/Blog/CreateBlog";
import UpdateBlog from "./pages/Blog/UpdateBlog";
import BlogCategories from "./pages/Blog/BlogCategories";
import ProductVariant from "./pages/ProductVariant/ProductVariant";
import ProductVariantCreate from "./pages/ProductVariant/ProductVariantCreate";
import ProductVariantUpdate from "./pages/ProductVariant/ProductVariantUpdate";
import ProductClaims from "./pages/ProductClaims/ProductClaims";
import BlogCategory from "./pages/BlogCategory/BlogCategory";
import HomePageImages from "./pages/HomePageImages/HomePageImages";
import HomePageSetting from "./pages/HomePageSetting/HomePageSetting";
import AboutPageSetting from "./pages/AboutPageSetting/AboutPageSetting";
import CatalogBanner from "./pages/CatalogBanner/CatalogBanner";
import QuizOptions from "./pages/Quiz/QuizOptions";
import Quiz from "./pages/Quiz/Quiz";
import BeautyMatchImages from "./pages/BeautyMatchImages";
import BeautyMatchLooks from "./pages/BeautyMatchLooks";
import StaticPage from "./pages/StaticPage/StaticPage";
import StaticPageCreate from "./pages/StaticPage/StaticPageCreate";
import StaticPageUpdate from "./pages/StaticPage/StaticPageUpdate";
import Discount from "./pages/Discount/Discount";
import DiscountCreate from "./pages/Discount/DiscountCreate";
import DiscountUpdate from "./pages/Discount/DiscountUpdate";
import TransactionList from "./pages/TransactionList/TransactionList";
import TransactionListUpdate from "./pages/TransactionList/TransactionListUpdate";
import ProductBulk from "./pages/ProductBulk";
import RequestPickup from "./pages/RequestPickup/RequestPickup";

const pagesList = [
  // with parent
  {
    route: "/dashboard/discount",
    component: Discount,
    childs: [
      {
        route: "/dashboard/discount/create",
        component: DiscountCreate,
        access: "create",
      },
      {
        route: "/dashboard/discount/update/:id",
        component: DiscountUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/product-variants",
    component: ProductVariant,
    childs: [
      {
        route: "/dashboard/product-variants/create",
        component: ProductVariantCreate,
        access: "create",
      },
      {
        route: "/dashboard/product-variants/update/:id",
        component: ProductVariantUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/transaction-list",
    component: TransactionList,
    childs: [
      {
        route: "/dashboard/transaction-list/update/:id",
        component: TransactionListUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/blog",
    component: Blog,
    childs: [
      {
        route: "/dashboard/blog/create",
        component: CreateBlog,
        access: "create",
      },
      {
        route: "/dashboard/blog/update/:id",
        component: UpdateBlog,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/product",
    component: Product,
    childs: [
      {
        route: "/dashboard/product/create",
        component: ProductCreate,
        access: "create",
      },
      {
        route: "/dashboard/product/update/:id",
        component: ProductUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/role",
    component: Role,
    childs: [
      {
        route: "/dashboard/role/create",
        component: CreateRole,
        access: "create",
      },
      {
        route: "/dashboard/role/update/:id",
        component: UpdateRole,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/static-page",
    component: StaticPage,
    childs: [
      {
        route: "/dashboard/static-page/create",
        component: StaticPageCreate,
        access: "create",
      },
      {
        route: "/dashboard/static-page/update/:id",
        component: StaticPageUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/pages",
    component: Page,
    childs: [
      {
        route: "/dashboard/pages/create",
        component: CreatePage,
        access: "create",
      },
    ],
    hasChild: true,
  },

  {
    route: "/dashboard/users",
    component: Users,
    childs: [
      {
        route: "/dashboard/users/update/:id",
        component: UpdateUser,
        access: "update",
      },
    ],
    hasChild: true,
  },

  // new
  {
    route: "/dashboard/quiz",
    component: Quiz,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/beauty-match-images",
    component: BeautyMatchImages,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/beauty-match-look",
    component: BeautyMatchLooks,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/quiz-options",
    component: QuizOptions,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/product-category",
    component: ProductCategory,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/about-page",
    component: AboutPageSetting,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/catalog-banner",
    component: CatalogBanner,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/blog-category",
    component: BlogCategory,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/page-images",
    component: HomePageImages,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/product-claims",
    component: ProductClaims,
    childs: [],
    hasChild: false,
  },

  {
    route: "/dashboard/home-page",
    component: HomePageSetting,
    childs: [],
    hasChild: false,
  },

  {
    route: "/dashboard/blog-categories",
    component: BlogCategories,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/product-stock",
    component: ProductBulk,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/request-pickup",
    component: RequestPickup,
    childs: [],
    hasChild: false,
  },

  // dashboard/details is hidden , i hide in login file
  {
    route: "/dashboard/account",
    component: AccountSetting,
    childs: [],
    hasChild: false,
  },
];

export default pagesList;
